/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Tabs, Tab, Form, Button, Modal, Row, Col } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import api from "../../Config/api";
import commonApi from "../../Config/CommonApi";
import { Navigation } from "swiper";
import Cancel from "../../images/cancel.svg";
import HoverCancel from "../../images/HoverCancel.svg";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { FormFeedback } from "reactstrap";
import Search from "../../images/search.svg";
import Search1 from "../../images/search1.svg";
import Cart from "../../images/cart.svg";
import Cartred from "../../images/caretred.svg";
import ImgPlusG from "../../images/imgPlusG.svg";
import ImgPlusR from "../../images/imgPlusR.png";
import ImgMinusG from "../../images/imgMinusG.svg";
import ImgMinusR from "../../images/imgMinusR.png";
import NotProductFound from "../../images/notproducctfound.png";
import Ico1 from "../../images/icon1.svg";
import { useNavigate } from "react-router-dom";
import Icon2 from "../../images/add.svg";
import Icon22 from "../../images/myplusR.svg";
import emtyLogo from "../../images/logo.png";
import Arrow_left from "../../images/arrow_left.svg";
import Arrow_right from "../../images/arrow_right.svg";
import Arrow_leftR from "../../images/arrow_left_fill.svg";
import Arrow_rightR from "../../images/arrow_right_fill.svg";

import WWW_Logo from "../../images/fluent-mdl2_website.png";
import Whatsapp_Logo from "../../images/logos_whatsapp-icon.png";
import Telegram_Logo from "../../images/logos_telegram.png";

import Footlogo from "../../images/footlogo.svg";
import Icon4 from "../../images/icon4.svg";
import Icon42 from "../../images/icon4.2.svg";
import Search2 from "../../images/search2.svg";

import Uparrow from "../../images/uparrow.svg";
import UparrowR from "../../images/uparrowR.svg";
import Bigico from "../../images/add.svg";
import Social1 from "../../images/social1.svg";
import Social11 from "../../images/social1.1.svg";
import Social2 from "../../images/social2.svg";
import Social22 from "../../images/social2.2.svg";
import Social3 from "../../images/social3.svg";
import Social33 from "../../images/social3.3.svg";
import Social4 from "../../images/bxl-twitter4.svg";
import Social44 from "../../images/bxl-twitter4.4.svg";
import Social5 from "../../images/youtub.png";
import Social55 from "../../images/youitubred.png";
import Social6 from "../../images/bxl-snapchat6.svg";
import Social66 from "../../images/bxl-snapchat6.6.svg";
import { ToastContainer, toast } from "react-toastify";

import Smiles from "../../images/smile.svg";
import Download from "../../images/download.png";

import Icofoot1 from "../../images/icofoot1.svg";
import Icofoot11 from "../../images/icofoot1.1.svg";
import Icofoot2 from "../../images/icofoot2.svg";
import Icofoot22 from "../../images/icofoot2.2.svg";
import Icofoot3 from "../../images/icofoot3.svg";
import Icofoot33 from "../../images/icofoot3.3.svg";
import Icofoot4 from "../../images/icofoot4.svg";
import Bars from "../../images/bars.svg";

import Side1 from "../../images/side1.svg";
import Side11 from "../../images/side1.1.svg";
import Side2 from "../../images/side2.svg";
import Side22 from "../../images/side2.2.svg";
import Side3 from "../../images/side3.svg";
import Side33 from "../../images/side3.3.svg";
import Side4 from "../../images/side4.svg";

import LangB from "../../images/langB.svg";
import LangR from "../../images/langR.svg";

import ShopFoot from "../../images/side1.svg";
import ShopFoot1 from "../../images/side1.1.svg";
import AboutusFoot from "../../images/side2.svg";
import AboutusFoot1 from "../../images/side2.2.svg";
import ContactusFoot from "../../images/side3.svg";
import ContactusFoot1 from "../../images/side3.3.svg";

import PlusGray from "../../images/add.svg";
import PlusRed from "../../images/myplusR.svg";
import minusBG from "../../images/minusBG.svg";
import minusB from "../../images/minusB.svg";

import Filtr_list from "../../images/filtr_list.svg";
import Filtr_list1 from "../../images/filtr_list1.svg";
import Filtr_grid from "../../images/filtr_grid.svg";
import Filtr_grid1 from "../../images/filtr_grid1.svg";

import jsPDF from "jspdf";

import QRCode from "qrcode.react";

import $ from "jquery";

const MySwal = withReactContent(Swal);

//>>>>>>>>>>>>>>>>>>>>>>>>>>>----- FORMIK-FORM-SCHEMAS ----->>>>>>>>>>>>>>>>>>>>>>>>>

const SignupSchemaCheckoutPage = yup.object().shape({
  //checkout
  customer_name: yup.string().required(),
  contact_no1: yup.string().required(),
});

const SignupSchemaContactUs = yup.object().shape({
  //ContactUs
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().required(),
  message: yup.string().required(),
});

const SignupSchemaContactorder = yup.object().shape({
  //ContactUs
  contact_no1: yup.string().required(),
  order_no: yup.string().required(),
});

function Home(props) {
  const navigate = useNavigate();

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    // downloadLink.download = `${qrValue}.png`;
    downloadLink.download = "index.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const SuccessToast = ({ data, message }) => {
    return (
      <Fragment>
        <div className="toastify-header">
          <div className="title-wrapper">
            <h6 className="toast-title">{message}</h6>
          </div>
        </div>
      </Fragment>
    );
  };

  const ErrorToast = ({ message }) => {
    return (
      <Fragment>
        <div className="toastify-header">
          <div className="title-wrapper">
            <h6 className="toast-title">Error!</h6>
          </div>
        </div>
        <div className="toastify-body">
          <span role="img" aria-label="toast-text">
            {message}
          </span>
        </div>
      </Fragment>
    );
  };

  //UseForm
  const {
    reset,
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      customer_name: "",
      email: "",
      contact_no1: "",
      address: "",
    },
    resolver: yupResolver(SignupSchemaCheckoutPage),
  });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>> ---- ContactUseForm ------>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const {
    reset: reset1,
    control: control1,
    setError: setError1,
    setValue: setValue1,
    handleSubmit: handleSubmit1,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      message: "",
    },
    resolver: yupResolver(SignupSchemaContactUs),
  });

  const {
    reset: reset2,
    control: control2,
    setError: setError2,
    setValue: setValue2,
    handleSubmit: handleSubmit2,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      order_no: "",
      contact_no1: "",
    },

    resolver: yupResolver(SignupSchemaContactorder),
  });

  let [count, setCount] = useState(1);
  let [countA, setCountA] = useState(0);

  function incrementCount() {
    count = count + 1;
    setCount(count);
  }

  function decrementCount() {
    if (count > 1) {
      count = count - 1;
      setCount(count);
    } else {
      count = count = 1;
      setCount(count);
    }
  }

  const [checked, setChecked] = useState([]);
  const [checked2, setChecked2] = useState([]);

  const [checkedPrice, setCheckedPrice] = useState([]);
  const [checkedName, setCheckedName] = useState([]);
  const [Addname, setAddName] = useState([]);

  const [TotalPriceAddons, setTotalPriceAddons] = useState([]);
  const [varName, setVarName] = useState([]);
  const [categoryIdClickMobile, setCategoryIdClickMobile] = useState(0);

  const [variantId, setVariantId] = useState([]);
  const [VariantPrice, setVariantPrice] = useState([]);
  const [wwwOrderMethodRadio, setWwwOrderMethodRadio] = useState(false);
  const [whatsappOrderMethodRadio, setWhatsappOrderMethodRadio] =
    useState(false);

  const setOrderMethodRadio = (method) => {
    if (method == "www") {
      setWwwOrderMethodRadio(true);
      setWhatsappOrderMethodRadio(false);
      setOrderMethod("www");
    } else {
      setWhatsappOrderMethodRadio(true);
      setWwwOrderMethodRadio(false);
      setOrderMethod("whatsapp");
    }
  };

  const radioChangeVariant = (e, data, variantName) => {
    let VariantName = e.target.name;
    let price = data.sales_price;
    let regular_price = data.regular_price;
    let Id = parseInt(e.target.value);
    let ChoosevariantName = data.variant_name;
    let optionID = {
      [VariantName]: Id,
    };

    setVariantId({ ...variantId, ...optionID });

    let optionObj = {
      Id,
      price,
      regular_price,
    };

    setVariantPrice({ ...VariantPrice, [variantName]: optionObj });

    //-------------------------------------------cart data views

    let optionID1 = {
      [VariantName]: ChoosevariantName,
    };

    //show lable in cart page
    //store id cartpage
    setVarName({ ...varName, ...optionID1 });
  };

  let sum = 0;
  let regular_price_sum = 0;

  for (const key in VariantPrice) {
    sum += VariantPrice[key].price;
    regular_price_sum += VariantPrice[key].regular_price;
  }

  const handleOnChange = (data, checked1) => {
    var updatedList = [...checked];
    if (checked1 == true) {
      updatedList = [...checked, parseInt(data.id)];
    } else {
      updatedList.splice(checked.indexOf(parseInt(data.id)), 1);
    }
    setChecked(updatedList);

    var updatedList1 = [...checkedPrice];
    if (checked1 == true) {
      updatedList1 = [...checkedPrice, parseInt(data.price)];
    } else {
      updatedList1.splice(checkedPrice.indexOf(parseInt(data.price)), 1);
    }
    setCheckedPrice(updatedList1);
    const TotalAmountAddons = updatedList1.reduce((a, v) => (a = a + v), 0);

    setTotalPriceAddons(TotalAmountAddons);

    //show name in Cartpage only ----------------------------------

    var updatedList2 = [...checked2];
    if (checked1 == true) {
      updatedList2 = [...checked2, parseInt(data.id)];
    } else {
      updatedList2.splice(checked2.indexOf(parseInt(data.id)), 1);
    }
    setChecked2(updatedList2);

    var updatedList3 = [...checkedName];
    if (checked1 == true) {
      updatedList3 = [...checkedName, data.addon_name];
    } else {
      updatedList3.splice(checkedName.indexOf(data.addon_name), 1);
    }

    setCheckedName(updatedList3);

    // const TotalAmountAddonsName = (updatedList3)
    const TotalAmountAddonsName = updatedList3;

    setAddName(TotalAmountAddonsName);
  };

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

  let [count2, setCount2] = useState(1);
  function incrementCount2() {
    count2 = count2 + 1;
    setCount2(count2);
  }
  function decrementCount2() {
    count2 = count2 - 1;
    setCount2(count2);
  }

  let [count3, setCount3] = useState(1);
  function incrementCount3() {
    count3 = count3 + 1;
    setCount3(count3);
  }
  function decrementCount3() {
    count3 = count3 - 1;
    setCount3(count3);
  }

  let [count4, setCount4] = useState(1);
  function incrementCount4() {
    count4 = count4 + 1;
    setCount4(count4);
  }
  function decrementCount4() {
    count4 = count4 - 1;
    setCount4(count4);
  }

  $(document).on("click", "#next_sec_btn", function () {
    $("#check_step1").hide();
    $(this).parents(".modal-body").find(".modal_cancel").hide();
    $("#check_step2").show();
    $(".resp_footer").hide();
    $(".modal_cancel").hide();
  });

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 100) {
      $(".header").addClass("fixedHead");
    } else {
      $(".header").removeClass("fixedHead");
    }
  });

  $(document).on("click", ".search_btns", function () {
    $("html, body").animate(
      {
        scrollTop: $("#products").offset().top - -140,
      },
      100
    );
    $(".search_filter").show();
    $(".modal").hide();
    $(".modal-backdrop").removeClass("show");
    $("#card_red").hide();
    $("#card_black").show();
    $(this).hide();
    $(".search_btns1").show();
  });

  const [show, setShow] = useState(false);
  const [checkoutshow, setcheckoutshow] = useState(false);
  const [checkoutshow1, setcheckoutshow1] = useState(false);
  const [checkoutshow2, setcheckoutshow2] = useState(false);
  const [checkoutshow3, setcheckoutshow3] = useState(false);

  //STATES
  const [categoryshow, setcategoryshow] = useState(false);
  const [filtershow, setfiltershow] = useState(false);
  const [bData, setBData] = useState([]);
  const [bData1, setBData1] = useState([]);
  const [Banner, setBanner] = useState("");

  const [Cartitems, setCartitems] = useState([]);
  const [CartitemsAmount, setCartitemsAmount] = useState([]);
  const [CartitemsExtras, setCartitemsExtras] = useState({});

  const [AboutUs, setAboutUs] = useState("");
  const [Settings, setSettings] = useState("");

  const [Settingsarab, setSettingsArab] = useState([]);

  const [Settingsenglish, setSettingsEnglish] = useState([]);

  const [SettingsInfoLogo, setSettingsInfoLogo] = useState("");

  const [couponcode, setcouponcode] = useState("");
  const [ordermobile, setordermobile] = useState("");

  const [CouponId, setCouponId] = useState("");

  const [Data, setData] = useState("");

  const [DataAddons, setDataAddons] = useState([]);
  let [VariantData, setVariantData] = useState([]);

  const [checkordermobi, setcheckordermobi] = useState([]);
  const [checkordermobi1, setcheckordermobi1] = useState("");

  const [OrderidData, setOrderidData] = useState([]);
  const [OrderidData1, setOrderidData1] = useState("");

  const [category, setCategory] = useState([]);
  const [selectButtons, setSelectButton] = useState();
  const [selectButtonsmobi, setSelectButtonmobi] = useState();

  const [selectCategory, setSelectCategory] = useState("all");

  const [orderMethod, setOrderMethod] = useState();

  const [checkoutData, setCheckoutData] = useState();


const handleDirectionToShop = () => {
    window.open(Settings && Settings.shop_location,
      '_blank'
    );
  }
  const handleClose = () => {
    setVarName([]);
    setAddName([]);
    setChecked([]);
    setVariantId([]);
    setTotalPriceAddons([]);
    setVariantPrice([]);
    setCheckedPrice([]);
    setChecked2([]);
    setCheckedName([]);
    setCount(1);

    setShow(false);
  };
  const handleShow = () => setShow(true);
  const checkOutClose = () => setcheckoutshow(false);
  const checkOutClose1 = () => setcheckoutshow1(false);
  const checkOutClose2 = () => setcheckoutshow2(false);
  const checkOutClose3 = () => setcheckoutshow3(false);
  const categoryClose = () => setcategoryshow(false);
  const filterClose = () => setfiltershow(false);
  const openCategoryModel = () => {
    setcategoryshow(true);
  };

  const openFilterModel = () => {
    setfiltershow(true);
  };

  const CheckoutModelAction = () => {
    setShow(false);
    setcheckoutshow(true);

    $(".header").addClass("showOnTop");
    $(".footer").addClass("footerFixed");
    $(".footer").hide();
    const CartId = JSON.parse(localStorage.getItem("Cart_id"));
    api.get(commonApi.GetAllCartEng + CartId).then((res) => {
      let data = res.data.result;
      let subTotal = 0;

      data.cart_items.forEach((element) => {
        subTotal += element.sales_price * element.quantity;
      });
      data.amounts.sub_total = subTotal;
      data.amounts.total = subTotal + data.amounts.tax_amount;
      setCartitems(data && data.cart_items);
      setCartitemsAmount(data && data.amounts);
    });
  };

  const closeSearchCategory = () => {
    $("#search_input").val("");
    setordermobile("");
    setSelectCategory("all");
    $("select option:nth(0)").prop("selected", "selected");
    getAllFunction();
  };

  const OpenTrackOrder = () => {
    setcheckoutshow(false);
    setcheckoutshow1(true);
  };

  $(document).on("click", "#listview_btn", function () {
    $("#grid_view").hide();
    $("#list_view").show();
    $(".shop_filter li").removeClass("active");
    $(this).parent().addClass("active");
  });

  $(document).on("click", "#gridview_btn", function () {
    $("#list_view").hide();
    $("#grid_view").show();
    $(".shop_filter li").removeClass("active");
    $(this).parent().addClass("active");
  });

  $(document).on("click", ".modal_cancel", function () {
    $(".header").removeClass("showOnTop");
    $(".footer").removeClass("footerFixed");
    $(".resp_footer").show();
    $("#card_red").hide();
    $("#card_black").show();
    $(".footer").show();
  });

  $(document).on("click", "#filter_close", function () {
    $(".search_filter").hide();
    $(".header_inside").show();
    $("html, body").animate(
      {
        scrollTop: $("#root").offset().top - 0,
      },
      100
    );
    $(".resp_footer").show();
    $(".search_btns1").hide();
    $(".search_btns").show();
  });

  $(document).on("click", ".modal_slide_left", function () {
    $(".swiper-button-prev").trigger("click");
  });
  $(document).on("click", ".modal_slide_right", function () {
    $(".swiper-button-next").trigger("click");
  });
  $(document).on("click", ".fa-xmark", function () {
    $(".header").removeClass("showOnTop");
    $(".footer").removeClass("footerFixed");
  });
  $(document).on("click", "#lastClose", function () {
    $(".header").removeClass("showOnTop");
    $(".footer").removeClass("footerFixed");
  });

  $(document).on("click", ".openSidebar", function () {
    $(".sidebar_sec").show();
    $(this).hide();
    $(".closeSidebar").show();
  });
  $(document).on("click", ".closeSidebar", function () {
    $(".sidebar_sec").hide();
    $(this).hide();
    $(".openSidebar").show();
  });
  $(document).on("click", "#changeLanguage", function () {
    $("#engLang_sec").hide();
    $("#otherLang_sec").show();
    $(this).hide();
    $("#otherLanguage").show();
  });

  $(document).on("click", "#otherLanguage", function () {
    $("#engLang_sec").show();
    $("#otherLang_sec").hide();
    $(this).hide();
    $("#changeLanguage").show();
  });
  $(document).on("click", "#showSearchbar", function () {
    $(".header_inside").hide();
    $("html, body").animate(
      {
        scrollTop: $("#products").offset().top - -70,
      },
      100
    );
    $(".search_filter").show("showAlldiv");
    $(".resp_footer").hide();
  });

  $(document).on("click", ".aboutFootBtn", function () {
    $("#uncontrolled-tab-example-tab-shop").click(function () {});
  });

  $(document).on("click", "#sidetrack_ord", function () {
    $(".closeSidebar").hide();
    $(".openSidebar").show();
    $(this).parent().parent().parent().parent().hide();
  });

  $(document).on("click", ".backBtns", function () {
    setcheckoutshow(false);
    setcheckoutshow1(false);

    $(".header").removeClass("showOnTop");
    $(".footer").removeClass("footerFixed");
  });

  $(document).on("click", ".btnGridview", function () {
    $(this).hide();
    $(".btnListview").show();
    $("#gridview_btn").click();
  });
  $(document).on("click", ".btnListview", function () {
    $(this).hide();
    $(".btnGridview").show();
    $("#listview_btn").click();
  });

  $(document).on("click", ".btnShopSec", function () {
    $(".sidebar_sec, .closeSidebar").hide();
    $(".openSidebar").show();
    $("html, body").animate(
      {
        scrollTop: $("#shops_sec").offset().top - 160,
      },
      1
    );
    $("#uncontrolled-tab-example-tabpane-about").hide();
    $("#uncontrolled-tab-example-tabpane-contact").hide();
    $("#uncontrolled-tab-example-tabpane-shop").show();
    $(".btnAboutSec, .btnContactSec").removeClass("boldWeight");
    $(this).addClass("boldWeight");
    $("#imgShopG, #imgAboutR, #imgContactR").hide();
    $("#imgAboutG, #imgContactG").show();
    $("#imgShopR").show();
    $(".btnShopSec").addClass("boldWeight");
  });
  $(document).on("click", ".btnAboutSec", function () {
    $(".sidebar_sec, .closeSidebar").hide();
    $(".openSidebar").show();
    $("html, body").animate(
      {
        scrollTop: $("#products").offset().top - 50,
      },
      100
    );
    $("#uncontrolled-tab-example-tabpane-contact").hide();
    $("#uncontrolled-tab-example-tabpane-shop").hide();
    $("#uncontrolled-tab-example-tabpane-about").show().removeClass("fade");
    $(".btnShopSec, .btnContactSec").removeClass("boldWeight");
    $(this).addClass("boldWeight");
    $("#imgAboutG, #imgShopR, #imgContactR").hide();
    $("#imgShopG, #imgContactG").show();
    $("#imgAboutR").show();
    $(".btnAboutSec").addClass("boldWeight");
  });
  $(document).on("click", ".btnContactSec", function () {
    $(".sidebar_sec, .closeSidebar").hide();
    $(".openSidebar").show();
    $("html, body").animate(
      {
        scrollTop: $("#products").offset().top - 50,
      },
      100
    );
    $("#uncontrolled-tab-example-tabpane-shop").hide();
    $("#uncontrolled-tab-example-tabpane-about").hide();
    $("#uncontrolled-tab-example-tabpane-contact").show().removeClass("fade");
    $(".btnShopSec, .btnAboutSec").removeClass("boldWeight");
    $(this).addClass("boldWeight");
    $("#imgContactG, #imgShopR, #imgAboutR").hide();
    $("#imgShopG, #imgAboutG").show();
    $("#imgContactR").show();
    $(".btnContactSec").addClass("boldWeight");
  });

  $(document).on("click", ".swiper-slide span", function () {
    $(".swiper-slide").siblings().removeClass("swiper-slide-active1");

    $(this).parent().addClass("swiper-slide-active1");
  });

  $(document).on("mouseover", ".checkout_rows", function () {});

  var $radioButtons = $('input[type="radio"]');
  $radioButtons.click(function () {
    $radioButtons.each(function () {
      $(this).parent().toggleClass("checked11", this.checked);
    });
  });

  $(document).on("click", "#card_black", function () {
    $(this).hide();
    $(".search_filter").hide();
    $("#card_red").show();
    $(".modal-backdrop").addClass("ajDev");
    $(".modal-dialog-custom").addClass("ajDev");
  });

  $(document).on("scroll", function () {
    var y = $(this).scrollTop();
    if (y > 300) {
      $(".gotTop").fadeIn();
    } else {
      $(".gotTop").fadeOut();
    }
  });

  $(document).on("click", ".gotTop", function () {
    $("html, body").animate(
      {
        scrollTop: $("#root").offset().top,
      },
      100
    );
  });

  $(document).on("click", "#clearBtn", function () {
    $(this).parent().find("input.form-control").val("");
    setordermobile("");
  });
  $(document).on("click", "#clearBtnX", function () {
    $(this).parent().find("input.form-control").val("");
  });

  $(document).on("click", ".cartImg_outer", function () {
    $(".resp_footer").hide();
    $(".footer").hide();
  });

  $(document).on("mouseover", ".modal_slide_left", function () {
    $(this).hide();
    $(".modal_slide_leftR").show();
  });
  $(document).on("mouseout", ".modal_slide_leftR", function () {
    $(this).hide();
    $(".modal_slide_left").show();
  });
  $(document).on("mouseover", ".modal_slide_right", function () {
    $(this).hide();
    $(".modal_slide_rightR").show();
  });
  $(document).on("mouseout", ".modal_slide_rightR", function () {
    $(this).hide();
    $(".modal_slide_right").show();
  });

  $(document).on("click", "ul.sizeUL li label", function () {
    $("ul.sizeUL li").siblings().removeClass("changeBG");
    $(this).parent().addClass("changeBG");
  });

  $(document).on("click", "ul.colorUL li label", function () {
    $("ul.colorUL li").siblings().removeClass("changeBG");
    $(this).parent().addClass("changeBG");
  });

  function over(e) {
    e.currentTarget.src = Icon22;
  }
  function out(e) {
    e.currentTarget.src = Icon2;
  }

  function listviewDark(e) {
    e.currentTarget.src = Filtr_list;
  }
  function listviewGray(e) {
    e.currentTarget.src = Filtr_list1;
  }

  function gridviewDark(e) {
    e.currentTarget.src = Filtr_grid;
  }
  function gridviewGray(e) {
    e.currentTarget.src = Filtr_grid1;
  }

  function imgPlusG(e) {
    e.currentTarget.src = ImgPlusG;
  }
  function imgPlusR(e) {
    e.currentTarget.src = ImgPlusR;
  }
  function imgMinusG(e) {
    e.currentTarget.src = ImgMinusG;
  }
  function imgMinusR(e) {
    e.currentTarget.src = ImgMinusR;
  }

  function imgFacebookG(e) {
    e.currentTarget.src = Social1;
  }
  function imgFacebookR(e) {
    e.currentTarget.src = Social11;
  }
  function imgInstaG(e) {
    e.currentTarget.src = Social2;
  }
  function imgInstaR(e) {
    e.currentTarget.src = Social22;
  }
  function imgWhatsAppG(e) {
    e.currentTarget.src = Social3;
  }
  function imgWhatsAppR(e) {
    e.currentTarget.src = Social33;
  }

  function imgTwitterAppG(e) {
    e.currentTarget.src = Social4;
  }
  function imgTweeterAppR(e) {
    e.currentTarget.src = Social44;
  }
  function imgYoutubAppG(e) {
    e.currentTarget.src = Social5;
  }
  function imgYoutubAppR(e) {
    e.currentTarget.src = Social55;
  }
  function imgSnapAppG(e) {
    e.currentTarget.src = Social6;
  }
  function imgSnapAppR(e) {
    e.currentTarget.src = Social66;
  }
  function upArrowGray(e) {
    e.currentTarget.src = Uparrow;
  }
  function upArrowRed(e) {
    e.currentTarget.src = UparrowR;
  }

  function languageGray(e) {
    e.currentTarget.src = LangB;
  }
  function languageRed(e) {
    e.currentTarget.src = LangR;
  }

  function imgPlusGray(e) {
    e.currentTarget.src = PlusGray;
  }
  function imgPlusRed(e) {
    e.currentTarget.src = PlusRed;
  }
  function minusBGray(e) {
    e.currentTarget.src = minusBG;
  }
  function minusBRed(e) {
    e.currentTarget.src = minusB;
  }

  function trackGray(e) {
    e.currentTarget.src = Icon4;
  }
  function trackRed(e) {
    e.currentTarget.src = Icon42;
  }

  $(document).on("click", "#langEnglish", function () {
    $(this).hide();
    $("#langArabic").show();
  });
  $(document).on("click", "#langArabic", function () {
    $(this).hide();
    $("#langEnglish").show();
  });

  $(document).on("click", "#langEnglish1", function () {
    $(this).hide();
    $("#langArabic1").show();
  });
  $(document).on("click", "#langArabic1", function () {
    $(this).hide();
    $("#langEnglish1").show();
  });

  $(document).on("mouseover", ".uparrowGray", function () {
    $(".uparrowGray").hide();
    $(".uparrowRed").show();
  });
  $(document).on("mouseover", ".uparrowRed", function () {
    $(".uparrowRed").hide();
    $(".uparrowGray").show();
  });

  $(document).on("click", ".myExtra li input", function () {
    $(".myExtra li").siblings().removeClass("fontBold");
    $(this).parent().addClass("fontBold");
  });

  $(document).on("click", "#myCategoryBtn", function () {
    $(this).addClass("addRed");
    $("#icoFootGrayBtn").hide();
    $("#icoFootRedBtn").show();
    $(".modal-backdrop").addClass("mytransparent");
  });

  $(document).on("click", "#removeTransparent", function () {
    $("#myCategoryBtn").removeClass("addRed");
    $("#icoFootGrayBtn").show();
    $("#icoFootRedBtn").hide();
    $(".modal-backdrop").removeClass("mytransparent");
  });

  $(document).on("click", ".newcategory_sec", function () {
    $("#myCategoryBtn").removeClass("addRed");
    $("#icoFootGrayBtn").show();
    $("#icoFootRedBtn").hide();
  });

  $(document).on("click", "#filtersBtns", function () {
    $(".modal-backdrop").addClass("mytransparent");
  });

  $(document).on("click", "#removeFilters", function () {
    $(".modal-backdrop").removeClass("mytransparent");
    $(".modal").hide();
  });

  $(document).on("click", "#mytrack", function () {
    $(this).hide();
    $("#mytrackRed").show();
  });
  $(document).on("click", "#mytrackRed", function () {
    $(this).hide();
    $("#mytrack").show();
  });
  $(document).on("click", ".backBtns", function () {
    $("#mytrackRed").hide();
    $("#mytrack").show();
  });

  $(document).on("click", "#graidRedBtn", function () {
    $("#listRedBtn").hide();
    $("#listGrayBtn").show();
    $(this).show();
  });
  $(document).on("click", "#graidGrayBtn", function () {
    $("#listRedBtn").hide();
    $("#listGrayBtn").show();
    $(this).hide();
    $("#graidRedBtn").show();
  });
  $(document).on("click", "#listGrayBtn", function () {
    $("#graidRedBtn").hide();
    $("#graidGrayBtn").show();
    $(this).hide();
    $("#listRedBtn").show();
  });

  function listViewGray(e) {
    e.currentTarget.src = Filtr_list1;
  }
  function listViewRed(e) {
    e.currentTarget.src = Filtr_list;
  }

  function gridViewGray(e) {
    e.currentTarget.src = Filtr_grid1;
  }
  function gridViewRed(e) {
    e.currentTarget.src = Filtr_grid;
  }

  function icoFootGray(e) {
    e.currentTarget.src = Icofoot3;
  }
  function icoFootRed(e) {
    e.currentTarget.src = Icofoot33;
  }

  $(document).on("click", "#fiterGrayBtn", function () {
    $(this).hide();
    $(this).parent().addClass("addRed");
    $("#fiterRedBtn").show();
  });
  $(document).on("click", "#removeFilters", function () {
    $("#fiterRedBtn").hide();
    $("#filtersBtns").removeClass("addRed");
    $("#fiterGrayBtn").show();
  });
  $(document).on("click", "#filtersBtns", function () {
    $("#fiterGrayBtn").hide();
    $("#fiterRedBtn").show();
    $("#filtersBtns").addClass("addRed");
  });

  $(document).on("click", "#go_backmodal", function () {
    setcheckoutshow(true);
    setcheckoutshow1(true);
  });

  const onOrderMethodChange = (e) => {
    setOrderMethod(e.target.value);
    setOrderMethodRadio(e.target.value);
  };

  const showWebQrCode = () => {
    $(".orderMethodsHeader").hide();
    $(".wwwMethodContent").show();
  };

  function handleNextItem() {
    countA = countA + 1;
    setCountA(countA);

    if (bData[countA].id == null) {
      setData(bData[0]);
    } else {
      setData(bData[countA]);

      const id = bData[countA].id;
      if (localStorage.getItem("Arab")) {
        api.get(commonApi.getbyidproductsArb + id).then((res) => {
          if (res.data.result.variants) {
            let Variant = res.data.result.variants;

            setVariantData(Variant);
            let data1 = res.data.result.extras;
            getAllFunction({});

            setDataAddons(data1);
          } else {

            api.get(commonApi.getbyidproductsEng + id).then((res) => {
              if (res.data.result.variants) {
                let Variant = res.data.result.variants;
                let data = res.data.result;

                let data1 = res.data.result.extras;

                setDataAddons(data1);
                setVariantData(Variant);

                setData(data);
              } else {
                let data = res.data.result;
                let data1 = res.data.result.extras;

                setDataAddons(data1);
                setVariantData([]);
              }

              // c
            });
          }
        });
        setShow(true);
      } else {
        api.get(commonApi.getbyidproductsEng + id).then((res) => {
          if (res.data.result.variants) {
            let Variant = res.data.result.variants;

            setVariantData(Variant);
            let data1 = res.data.result.extras;
            getAllFunction({});

            setDataAddons(data1);
          } else {
            let data = res.data.result;
            let data1 = res.data.result.extras;
            let Variant = res.data.result.variants;

            setVariantData([]);

            setDataAddons(data1);
          }
        });
        setShow(true);
      }
    }
  }

  function handlePrevItem() {
    countA = countA - 1;
    setCountA(countA);

    if (bData[countA] == 0) {
      setData(bData[0]);
    } else {
      setData(bData[countA]);

      const id = bData[countA].id;

      if (localStorage.getItem("Arab")) {
        api.get(commonApi.getbyidproductsArb + id).then((res) => {
          if (res.data.result.variants) {
            let Variant = res.data.result.variants;
            // let ColorData = res.data.result.variants.COLOR;

            setVariantData(Variant);
            let data1 = res.data.result.extras;
            getAllFunction({});

            setDataAddons(data1);
          } else {
            api.get(commonApi.getbyidproductsEng + id).then((res) => {
              if (res.data.result.variants) {
                let Variant = res.data.result.variants;
                let data = res.data.result;

                let data1 = res.data.result.extras;

                setDataAddons(data1);
                setVariantData(Variant);
              } else {
                let data = res.data.result;
                let data1 = res.data.result.extras;

                setDataAddons(data1);
                setVariantData([]);
              }

              // c
            });
          }
        });
        setShow(true);
      } else {
        api.get(commonApi.getbyidproductsEng + id).then((res) => {
          if (res.data.result.variants) {
            let Variant = res.data.result.variants;

            setVariantData(Variant);
            let data1 = res.data.result.extras;
            getAllFunction({});

            setDataAddons(data1);
          } else {
            let data = res.data.result;
            let data1 = res.data.result.extras;
            let Variant = res.data.result.variants;

            setVariantData([]);

            setDataAddons(data1);
          }
        });
        setShow(true);
      }
    }

    getAllFunction({});
  }

  const handleEditClick = (data) => {
    let id;
    if (data?.id) {
      id = data.id;
    } else{
      id = data;
    }

    if (localStorage.getItem("Arab")) {
      api.get(commonApi.getbyidproductsArb + id).then((res) => {
        if (res.message	== 'Record not found!') {
          return;
        }
        if (res.data.result.variants) {
          let Variant = res.data.result.variants;
          let data1 = res.data.result.extras;

          setVariantData(Variant);
          getAllFunction({});
          setDataAddons(data1);
          setData(res.data.result);
          // setData(data);
        } else {
          api.get(commonApi.getbyidproductsEng + id).then((res) => {
            if (res.message	== 'Record not found!') {
              return;
            }
            if (res.data.result.variants) {
              let Variant = res.data.result.variants;
              let data = res.data.result;
              let data1 = res.data.result.extras;

              setDataAddons(data1);
              setVariantData(Variant);
              setData(data);
            } else {
              let data = res.data.result;
              let data1 = res.data.result.extras;

              setData(data);
              setDataAddons(data1);
              setVariantData([]);
            }
          });
        }
        setShow(true);
      });
    } else {
      api.get(commonApi.getbyidproductsEng + id).then((res) => {
        if (res.message	== 'Record not found!') {
          return;
        }
        if (res.data.result.variants) {
          let Variant = res.data.result.variants;
          let data1 = res.data.result.extras;

          setVariantData(Variant);
          setDataAddons(data1);
          setData(res.data.result);
          getAllFunction({});
        } else {
          let data = res.data.result;
          let data1 = res.data.result.extras;
          let Variant = res.data.result.variants;

          setVariantData([]);

          setData(res.data.result);
          setDataAddons(data1);
        }
        setShow(true);
      });
    }
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- HANDELE COUPON CODE  ----->>>>>>>>>>>>>>>>>>>>>>>>>

  const hendelcouponcode = () => {
    const CartId = JSON.parse(localStorage.getItem("Cart_id"));
    let postdata = {
      cart_id: CartId,
      coupon: couponcode,
    };

    api.post(commonApi.getAllCoupons, postdata).then((res) => {
      setCouponId(res.data.result);
    });
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- GET ALL PRODUCTS ----->>>>>>>>>>>>>>>>>>>>>>>>>

  const ArabLanguage = () => {
    api.get(commonApi.getAllproductsArb).then((res) => {
      let data = res.data.result;
      let data1 = res.data.result.variants;

      localStorage.setItem("Arab", JSON.stringify(data));
      const localStorageData = JSON.parse(localStorage.getItem("Arab"));
      setBData(localStorageData);
      setBData1(data1);

      getAllFunction({});
    });
  };

  const EnglishLanguage = () => {
    localStorage.removeItem("Arab");
    getAllFunction({});
  };

  const handlesearch = (data) => {
    // if (selectCategory === "all") {
    // getAllFunction();return;
    // }
    if (localStorage.getItem("Arab")) {
      api
        .get(
          commonApi.getAllproductsEng +
            `?product_name=${ordermobile}&category_id=${selectCategory}`
        )
        .then((res) => {
          let data = res.data.result;
          setBData(data);
        });
    } else {
      api
        .get(
          commonApi.getAllproductsEng +
            `?product_name=${ordermobile}&category_id=${selectCategory}`
        )
        .then((res) => {
          let data = res.data.result;

          setBData(data);
        });
    }
  };

  //

  const handleFilter = (data) => {
    setfiltershow(false);
    if (data == "PriceLowtoHigh") {
      if (localStorage.getItem("Arab")) {
        api.get(commonApi.getAllproductsArb).then((res) => {
          let newData = res.data.result;

          let filterdata = newData.sort(
            (firstItem, secondItem) =>
              firstItem.sales_price - secondItem.sales_price
          );

          setBData(filterdata);
        });
      } else {
        api.get(commonApi.getAllproductsEng).then((res) => {
          let newData = res.data.result;

          let filterdata = newData.sort(
            (firstItem, secondItem) =>
              firstItem.sales_price - secondItem.sales_price
          );

          setBData(filterdata);
        });
      }
    } else {
      if (localStorage.getItem("Arab")) {
        api.get(commonApi.getAllproductsArb).then((res) => {
          let newData = res.data.result;

          let filterdata = newData.sort(
            (firstItem, secondItem) =>
              secondItem.sales_price - firstItem.sales_price
          );

          setBData(filterdata);
        });
      } else {
        api.get(commonApi.getAllproductsEng).then((res) => {
          let newData = res.data.result;

          let filterdata = newData.sort(
            (firstItem, secondItem) =>
              secondItem.sales_price - firstItem.sales_price
          );

          setBData(filterdata);
        });
      }
    }
  };

  const data = Settings && Settings.shop_name;

  document.title = data;
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  link.href = Settings && Settings.logo;

  const getAllFunction = () => {
    setSelectButton("all");
    setSelectButtonmobi("AllMobiData");

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- GET LOCAL STORAGE DATA  ----->>>>>>>>>>>>>>>>>>>>>>>>>

    $(".swiper-slide").siblings().removeClass("swiper-slide-active1");

    if (localStorage.getItem("Arab")) {
      const localStorageData = JSON.parse(localStorage.getItem("Arab"));

      setBData(localStorageData);
    } else {
      api.get(commonApi.getAllproductsEng).then((res) => {
        let data = res.data.result;
        let data1 = res.data.result.variants;
        setBData(data);
        setBData1(data1);
      });
    }

    if (localStorage.getItem("Arab")) {
      api.get(commonApi.getAllcategoryArb).then((res) => {
        let data = res.data.result;
        setCategory(data);
      });
    } else {
      api.get(commonApi.getAllcategoryEng).then((res) => {
        let data = res.data.result;
        setCategory(data);
      });
    }

    api.get(commonApi.getAllSettings).then((res) => {
      let data = res.data.result;
      let Info_logo = data.infogram_data;
      setSettings(data);
      setSettingsInfoLogo(Info_logo);
    });

    if (localStorage.getItem("Arab")) {
      api.get(commonApi.getAllSettingsArb).then((res) => {
        let data = res.data.result.infogram_data;
        setSettingsArab(data);
        setSettingsEnglish(data);
      });
    } else {
      api.get(commonApi.getAllSettingsEng).then((res) => {
        let data = res.data.result.infogram_data;
        setSettingsEnglish(data);
        setSettingsArab(data);
      });
    }

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>-----GET ALL BANNERS ----->>>>>>>>>>>>>>>>>>>>>>>>>

    api.get(commonApi.BannerGetall).then((res) => {
      let data = res.data.result.banners;
      setBanner(data);
    });

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- GETALL-CART-ID-BY-CART-ITEMS ----->>>>>>>>>>>>>>>>>>>>>>>>>
    const CartId = JSON.parse(localStorage.getItem("Cart_id"));

    if (localStorage.getItem("Arab")) {
      api.get(commonApi.GetAllCartArb + CartId).then((res) => {
        let data = res.data.result;

        setCartitems(data && data.cart_items);
        setCartitemsAmount(data && data.amounts);
      });
    } else {
      api.get(commonApi.GetAllCartEng + CartId).then((res) => {
        let data = res.data.result;

        setCartitems(data && data.cart_items);
        setCartitemsAmount(data && data.amounts);
      });
    }

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- GETALL ABOUT US ----->>>>>>>>>>>>>>>>>>>>>>>>>

    if (localStorage.getItem("Arab")) {
      api.get(commonApi.getAllAboutUsArb).then((res) => {
        let data = res.data.result;

        setAboutUs(data);
      });
    } else {
      api.get(commonApi.getAllAboutUsEng).then((res) => {
        let data = res.data.result;

        setAboutUs(data);
      });
    }
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- POST CONTACT US ----->>>>>>>>>>>>>>>>>>>>>>>>>

  const PostContactUs = (data) => {
    let postData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      message: data.message,
    };

    api.post(commonApi.ContactUs, postData).then((res) => {
      let data = res.data;
      if (data && data.success === true) {
        toast.success(data && data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        MySwal.fire({
          icon: "danger",
          title: "Error!",
          text: "Network Error",
          customClass: {
            confirmButton: "btn btn-error",
          },
        });
      }
    });
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- GET CATEGORY ID BY PRODUCTS ----->>>>>>>>>>>>>>>>>>>>>>>>>

  const handleCategoryIdClick = (data) => {
    const id = data.id;

    setCategoryIdClickMobile(id);
    if (localStorage.getItem("Arab")) {
      api.get(commonApi.getCategoryIdbyProductsArb + id).then((res) => {
        let data = res.data.result;
        setBData(data);
        setSelectButton("category");
        setSelectButtonmobi("categorymobi");
      });
    } else {
      api.get(commonApi.getCategoryIdbyProductsEng + id).then((res) => {
        let data = res.data.result;

        setBData(data);
        setSelectButton("category");
        setSelectButtonmobi("category");
      });
    }
  };

  const handleCategoryIdClickmobile = (data) => {
    setcategoryshow(false);

    const id = data.id;

    $(".swiper-slide-active1").removeClass("swiper-slide-active1");
    $(".swap-" + id).addClass("swiper-slide-active1");

    setCategoryIdClickMobile(id);
    if (localStorage.getItem("Arab")) {
      api.get(commonApi.getCategoryIdbyProductsArb + id).then((res) => {
        let data = res.data.result;
        setBData(data);
        setSelectButtonmobi("categorymobi");
      });
    } else {
      api.get(commonApi.getCategoryIdbyProductsEng + id).then((res) => {
        let data = res.data.result;

        setBData(data);
        setSelectButtonmobi("category");
      });
    }
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- DELETE CART ID ----->>>>>>>>>>>>>>>>>>>>>>>>>

  const handleDeleteClick = (data, qty) => {
    const CartId = JSON.parse(localStorage.getItem("Cart_id"));

    let postdata = {
      product_id: data.product_id,
      cart_id: CartId,
    };

    api.post(commonApi.DeleteCartItems, postdata).then((res) => {
      toast({
        text: "This will become the toast message",
      });
      getAllFunction({});
    });
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- ADD TO CART ----->>>>>>>>>>>>>>>>>>>>>>>>>

  const handleAddToCartClickIcon = (data) => {
    if ("variants" in data) {
      handleEditClick(data);
      return;
    }
    if (data.product_type === "variable") {
      if (sum === 0) {
        Object.keys(data.variants).map((variantsName) => {
          {
            data.variants[variantsName].map((datas) => {
              if (datas.is_default === "yes") {
                if (localStorage.getItem("Cart_id")) {
                  const CartId = JSON.parse(localStorage.getItem("Cart_id"));

                  let postData = {
                    cart_id: CartId,
                    product_id: data.id,
                    shope_id: 1,
                    quantity: count,
                    sales_price: data.sales_price,
                    total_amount: count * data.sales_price,
                    variant_ids: { [variantsName]: datas.variant_id },
                    extras_ids: checked,
                  };

                  api.post(commonApi.CreateCart, postData).then((res) => {
                    let data = res.data;

                    if (data.success === true) {
                      toast.success(data && data.message, {
                        position: "top-center",
                        autoClose: 850,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else {
                      toast.error(data && data.errors.errorInfo, {
                        position: "top-center",
                        autoClose: 850,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }
                    setShow(false);

                    getAllFunction();
                  });
                  setVarName([]);
                  setAddName([]);
                  setChecked([]);
                  setVariantId([]);
                  setTotalPriceAddons([]);
                  setVariantPrice([]);
                  setCheckedPrice([]);
                  setChecked2([]);
                  setCheckedName([]);
                } else {
                  const CartId = Date.now();

                  let postData = {
                    cart_id: CartId,
                    product_id: data.id,
                    shope_id: 1,
                    quantity: count,
                    total_amount: count * data.sales_price,
                    sales_price: data.sales_price,
                    variant_ids: { [variantsName]: datas.variant_id },
                    extras_ids: checked,
                  };
                  localStorage.setItem(
                    "Cart_id",
                    JSON.stringify(postData.cart_id)
                  );

                  api.post(commonApi.CreateCart, postData).then((res) => {
                    let data = res.data;

                    if (data.success === true) {
                      toast.success(data && data.message, {
                        position: "top-center",
                        autoClose: 850,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else {
                      toast.error(data && data.errors.errorInfo, {
                        position: "top-center",
                        autoClose: 850,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }
                    setShow(false);

                    getAllFunction();
                  });
                }
              }
            });
          }
        });
      }

      // on click undefoult Values------------------------------------
      else {
        if (localStorage.getItem("Cart_id")) {
          const CartId = JSON.parse(localStorage.getItem("Cart_id"));

          let postData = {
            cart_id: CartId,
            product_id: data.id,
            shope_id: 1,
            quantity: count,
            sales_price: data.sales_price,
            total_amount: count * data.sales_price,
            variant_ids: variantId,
            extras_ids: checked,
          };

          api.post(commonApi.CreateCart, postData).then((res) => {
            let data = res.data;

            if (data.success === true) {
              toast.success(data && data.message, {
                position: "top-center",
                autoClose: 850,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error(data && data.errors.errorInfo, {
                position: "top-center",
                autoClose: 850,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
            setShow(false);

            getAllFunction();
          });
          setVarName([]);
          setAddName([]);
          setChecked([]);
          setVariantId([]);
          setTotalPriceAddons([]);
          setVariantPrice([]);
          setCheckedPrice([]);
          setChecked2([]);
          setCheckedName([]);
        } else {
          const CartId = Date.now();

          let postData = {
            cart_id: CartId,
            product_id: data.id,
            shope_id: 1,
            quantity: count,
            total_amount: count * data.sales_price,
            sales_price: data.sales_price,
            variant_ids: variantId,
            extras_ids: checked,
          };
          localStorage.setItem("Cart_id", JSON.stringify(postData.cart_id));

          api.post(commonApi.CreateCart, postData).then((res) => {
            let data = res.data;
            setShow(false);

            getAllFunction({});
          });
        }
      }
    }

    // onclick add to cart UnvariableData add to cart
    else {
      if (localStorage.getItem("Cart_id")) {
        const CartId = JSON.parse(localStorage.getItem("Cart_id"));

        let postData = {
          cart_id: CartId,
          product_id: data.id,
          shope_id: 1,
          quantity: count,
          sales_price: data.sales_price,
          variant_id: variantId,
          extras_ids: checked,
        };

        api.post(commonApi.CreateCart, postData).then((res) => {
          let data = res.data;

          if (data.success === true) {
            toast.success(data && data.message, {
              position: "top-center",
              autoClose: 850,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error(data && data.errors.errorInfo, {
              position: "top-center",
              autoClose: 850,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setShow(false);

          getAllFunction();
        });
        setVarName([]);
        setAddName([]);
        setChecked([]);
        setVariantId([]);
        setTotalPriceAddons([]);
        setVariantPrice([]);
        setCheckedPrice([]);
        setChecked2([]);
        setCheckedName([]);
      } else {
        const CartId = Date.now();

        let postData = {
          cart_id: CartId,
          product_id: data.id,
          shope_id: 1,
          quantity: count,
          sales_price: data.sales_price,
          variant_id: variantId,
          extras_ids: checked,
        };
        localStorage.setItem("Cart_id", JSON.stringify(postData.cart_id));

        api.post(commonApi.CreateCart, postData).then((res) => {
          let data = res.data;
          if (data.success === true) {
            toast.success(data && data.message, {
              position: "top-center",
              autoClose: 850,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error(data && data.errors.errorInfo, {
              position: "top-center",
              autoClose: 850,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setShow(false);

          getAllFunction({});
        });
      }
    }
  };

  const AddToCart = (data) => {
    if (data.product_type === "variable") {
      //defoultvalues get-----------------------------------
      if (sum === 0) {
        Object.keys(VariantData).map((variantName) => {
          {
            VariantData[variantName].map((datas) => {
              if (datas.is_default === "yes") {
                if (localStorage.getItem("Cart_id")) {
                  const CartId = JSON.parse(localStorage.getItem("Cart_id"));
                  let postData = {
                    cart_id: CartId,
                    product_id: data.id,
                    shope_id: 1,
                    quantity: count,
                    total_amount: count * data.sales_price,
                    sales_price: data.sales_price,
                    variant_ids: { [variantName]: datas.variant_id },
                    extras_ids: checked,
                  };

                  api.post(commonApi.CreateCart, postData).then((res) => {
                    let data = res.data;
                    if (data.success === true) {
                      toast.success(data && data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });

                      setVarName([]);
                      setAddName([]);
                      setChecked([]);
                      setVariantId([]);
                      setTotalPriceAddons([]);
                      setVariantPrice([]);
                      setCheckedPrice([]);
                      setChecked2([]);
                      setCheckedName([]);
                      setCount(1);
                    } else {
                      toast.error(data && data.errors.errorInfo, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }
                    setShow(false);

                    getAllFunction({});
                  });
                } else {
                  const CartId = Date.now();

                  let postData = {
                    cart_id: CartId,
                    product_id: Data.id,
                    shope_id: 1,
                    quantity: count,
                    total_amount: count * Data.sales_price + sum,
                    sales_price: Data.sales_price,
                    variant_ids: { [variantName]: datas.variant_id },
                    extras_ids: checked,
                  };
                  localStorage.setItem(
                    "Cart_id",
                    JSON.stringify(postData.cart_id)
                  );

                  api.post(commonApi.CreateCart, postData).then((res) => {
                    let data = res.data;

                    if (data.success === true) {
                      toast.success(data && data.message, {
                        position: "top-center",
                        autoClose: 850,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });

                      setVarName([]);
                      setAddName([]);
                      setChecked([]);
                      setVariantId([]);
                      setTotalPriceAddons([]);
                      setVariantPrice([]);
                      setCheckedPrice([]);
                      setChecked2([]);
                      setCheckedName([]);
                      setCount(1);
                    } else {
                      toast.error(data && data.errors.errorInfo, {
                        position: "top-center",
                        autoClose: 850,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }

                    setShow(false);
                    getAllFunction({});
                  });
                }
              }
            });
          }
        });
      } else {
        //undefoult values
        if (localStorage.getItem("Cart_id")) {
          const CartId = JSON.parse(localStorage.getItem("Cart_id"));
          let postData = {
            cart_id: CartId,
            product_id: data.id,
            shope_id: 1,
            quantity: count,
            total_amount: count * data.sales_price,
            sales_price: data.sales_price,
            variant_ids: variantId,
            extras_ids: checked,
          };

          api.post(commonApi.CreateCart, postData).then((res) => {
            let data = res.data;
            if (data.success === true) {
              toast.success(data && data.message, {
                position: "top-center",
                autoClose: 850,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              setVarName([]);
              setAddName([]);
              setChecked([]);
              setVariantId([]);
              setTotalPriceAddons([]);
              setVariantPrice([]);
              setCheckedPrice([]);
              setChecked2([]);
              setCheckedName([]);
            } else {
              toast.error(data && data.errors.errorInfo, {
                position: "top-center",
                autoClose: 850,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
            setShow(false);

            // setcheckoutshow(true);

            // $(".header").addClass("showOnTop");
            // $(".footer").addClass("footerFixed");
            // $(".footer").hide();

            getAllFunction({});
          });
        } else {
          const CartId = Date.now();

          let postData = {
            cart_id: CartId,
            product_id: Data.id,
            shope_id: 1,
            quantity: count,
            total_amount: count * Data.sales_price + sum,
            sales_price: Data.sales_price,
            variant_ids: variantId,
            extras_ids: checked,
          };
          localStorage.setItem("Cart_id", JSON.stringify(postData.cart_id));

          api.post(commonApi.CreateCart, postData).then((res) => {
            let data = res.data;

            if (data.success === true) {
              toast.success(data && data.message, {
                position: "top-center",
                autoClose: 850,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              setVarName([]);
              setAddName([]);
              setChecked([]);
              setVariantId([]);
              setTotalPriceAddons([]);
              setVariantPrice([]);
              setCheckedPrice([]);
              setChecked2([]);
              setCheckedName([]);
            } else {
              toast.error(data && data.errors.errorInfo, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }

            setShow(false);
            getAllFunction({});

            // setcheckoutshow(true);

            // $(".header").addClass("showOnTop");
            // $(".footer").addClass("footerFixed");
            // $(".footer").hide();
          });
        }
      }
    }

    //UnvariableData>>>>>>>>>>>>>>>>>>>>>>>>
    else {
      if (localStorage.getItem("Cart_id")) {
        const CartId = JSON.parse(localStorage.getItem("Cart_id"));
        let postData = {
          cart_id: CartId,
          product_id: data.id,
          shope_id: 1,
          quantity: count,
          sales_price: data.sales_price,
          variant_id: variantId,
          extras_ids: checked,
        };

        api.post(commonApi.CreateCart, postData).then((res) => {
          let data = res.data;
          if (data.success === true) {
            toast.success(data && data.message, {
              position: "top-center",
              autoClose: 850,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            setVarName([]);
            setAddName([]);
            setChecked([]);
            setVariantId([]);
            setTotalPriceAddons([]);
            setVariantPrice([]);
            setCheckedPrice([]);
            setChecked2([]);
            setCheckedName([]);
            setCount(1);
          } else {
            toast.error(data && data.errors.errorInfo, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setShow(false);

          // setcheckoutshow(true);

          // $(".header").addClass("showOnTop");
          // $(".footer").addClass("footerFixed");
          // $(".footer").hide();

          getAllFunction({});
        });
      } else {
        const CartId = Date.now();

        let postData = {
          cart_id: CartId,
          product_id: data.id,
          shope_id: 1,
          quantity: count,
          sales_price: data.sales_price,
          variant_id: variantId,
          extras_ids: checked,
        };
        localStorage.setItem("Cart_id", JSON.stringify(postData.cart_id));

        api.post(commonApi.CreateCart, postData).then((res) => {
          let data = res.data;

          if (data.success === true) {
            toast.success(data && data.message, {
              position: "top-center",
              autoClose: 850,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            setVarName([]);
            setAddName([]);
            setChecked([]);
            setVariantId([]);
            setTotalPriceAddons([]);
            setVariantPrice([]);
            setCheckedPrice([]);
            setChecked2([]);
            setCheckedName([]);
          } else {
            toast.error(data && data.errors.errorInfo, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }

          setShow(false);
          getAllFunction({});

        });
      }
    }
  };

  //>>>>>>>>>>>>>>>>>  CHECK_ORDER_BY_ORDER_NUMBER <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const hendleorderdetails = () => {
    setcheckoutshow2(false);
    setcheckoutshow3(true);
  };

  const hendelOrderByOrderNumber = (data) => {
    window.location = `/myorders/${data.order_no}/${data.contact_no1}`;
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>CHECKOUTPAGE & PLACE ORDER PAGE<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const saveCheckoutData = (data) => {
    setCheckoutData(data);
    $("#check_step1").hide();
    $(this).parents(".modal-body").find(".modal_cancel").hide();
    $("#check_step2").show();
    $(".resp_footer").hide();
    $(".modal_cancel").hide();
  };
  const handledcheckoutpage = () => {
    let data = checkoutData;

    const Address = JSON.parse(localStorage.getItem("address"));
    let postdata = {};

    const CartId = JSON.parse(localStorage.getItem("Cart_id"));
    if (CouponId == "") {
      postdata = {
        customer_name: data.customer_name,
        email: data.email,
        contact_no1: data.contact_no1,
        manual_address: data.manual_address,
        order_note: data.order_note,
        address: Address,
        cart_id: CartId,
        payment_mode: "cash on delivery",
        payment_status: "Done",
        status: "Pending",
        total_amount: CartitemsAmount && CartitemsAmount.total,
        order_method: orderMethod,
      };
    } else {
      postdata = {
        customer_name: data.customer_name,
        email: data.email,
        contact_no1: data.contact_no1,
        manual_address: data.manual_address,
        order_note: data.order_note,
        address: Address,
        cart_id: CartId,
        payment_mode: "cash on delivery",
        payment_status: "Done",
        status: "Pending",

        discount: CouponId.discount_amount,
        coupon_applied: CouponId.discount_amount,
        total_amount: CouponId && CouponId.total,
        order_method: orderMethod,
      };
    }

    api.post(commonApi.CreateOrder, postdata).then((res) => {
      let data = res.data.result;

      if (res.data.success === true) {
        localStorage.removeItem("Cart_id");
        localStorage.removeItem("address");

        api
          .get(commonApi.Orderid + `${data.order_no}/${data.contact_no1}`)
          .then((res) => {
            let data = res.data.result.orders[0].order_details;
            let data1 = res.data.result.orders[0];

            setOrderidData(data);
            setOrderidData1(data1);
            if (orderMethod == "www") {
              showWebQrCode();
            } else {
              sendWhatsapp(data, data1);
            }
          });
      }
    });
  };

  const sendWhatsapp = (data, data1) => {
    let number = WhatsAppNumber;
    let message = `NewOrder : ${data1 && data1.order_no}\nTotal items:${
      data && data.length
    }\n${
      data &&
      data.map(
        (data, index) =>
          `${index + 1} - ${data && data.product.product_name_en} ${
            data && data.product_variant && data.product_variant.variant_name_en
              ? data &&
                data.product_variant &&
                data.product_variant.variant_name_en
              : ""
          }${
            data && data.addons && data.addons.addon_name_en
              ? data && data.addons && data.addons.addon_name_en
              : ""
          } x ${data && data.quantity} = ${
            Settings && Settings.currency.code
          }  ${data && data.amount}\n`
      )
    }\n\nTotal Payable = ${Settings && Settings.currency.code} ${
      data1 && data1.total_amount.toFixed(2)
    } \n\nOrder Note:\nName: ${
      data1 && data1.customer.customer_name
    } \nMobile:${data1 && data1.customer.contact_no1}
    \nOrder note: ${data1 && data1.order_note}
    \nAddress line 1: ${data1 && data1.manual_address}
  
    \n===============\n\nPlease confirm via reply`;

    if (message.length <= 2000) {
      var whatsappURL =
        "https://api.whatsapp.com/send/?phone=" +
        number +
        "&text=" +
        encodeURIComponent(message) +
        "&type=phone_number&app_absent=0";
      setTimeout(function () {
        window.location.href = whatsappURL;
      }, 200);
    } else {
      var doc = new jsPDF();

      var maxCharsPerPage = 1200;

      for (var i = 0; i < message.length; i += maxCharsPerPage) {
        var pageText = message.substr(i, maxCharsPerPage);

        if (i > 0) {
          doc.addPage();
        }

        doc.text(10, 10, pageText);
      }

      var pdfBlob = doc.output("blob");

      var pdfUrl = URL.createObjectURL(pdfBlob);

      var downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "multi-page-order.pdf";

      document.body.appendChild(downloadLink);

      setTimeout(function () {
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.location.href =
          "https://api.whatsapp.com/send/?phone=" +
          number +
          "&text=" +
          encodeURIComponent("Please send the downloaded PDF file.") +
          "&type=phone_number&app_absent=0";
      }, 200);
    }
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>----- UPDATE CART QTY ----->>>>>>>>>>>>>>>>>>>>>>>>>

  const handleDecreamentQuantity = (data, qty) => {
    const CartId = JSON.parse(localStorage.getItem("Cart_id"));

    let value = data.quantity - 1;

    if (value < 1) {
      let postdata = {
        product_id: data.product_id,
        variant_id: data.variant_id,
        cart_id: CartId,
      };

      api.post(commonApi.DeleteCartItems, postdata).then((res) => {
        getAllFunction();
      });
    } else {
      let postdata = {
        product_id: data.product_id,
        variant_id: data.variant_id,

        quantity: value,
        cart_id: CartId,
      };

      api.post(commonApi.UpdateCartQty, postdata).then((res) => {
        getAllFunction();
      });
    }
  };

  const handleIncreamentQuantity = (data, quantity) => {
    const CartId = JSON.parse(localStorage.getItem("Cart_id"));
    let value = 1;
    let postdata = {
      product_id: data.product_id,
      variant_id: data.variant_id,

      quantity: data.quantity + value,
      cart_id: CartId,
    };
    api.post(commonApi.UpdateCartQty, postdata).then((res) => {
      getAllFunction();
    });
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ORDER_ON_WHATSUP >>>>>>>>>>>>>>>>>>>

  const WhatsAppNumber = `${Settings && Settings.shop_whatsapp}`;

  let { product_id } = useParams();
  useEffect(() => {
    if(product_id){
      handleEditClick(product_id)
    }
    getAllFunction([]);
  }, []);


  const isColorLight = (hex) => {
    if (typeof hex === 'undefined' || hex == null) {
      return true;
    }
    // Remove '#' if present
    hex = hex.replace(/^#/, '');

    // Convert shorthand hex (e.g., #abc) to full form (e.g., #aabbcc)
    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }

    // Convert hex to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.2126 * r) + (0.7152 * g) + (0.0722 * b);

    return luminance > 128; // Returns true if light, false if dark
  };
  

  if (Settings.settings_account_status === "disabled") {
    return (
      <html
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
          height: "100%",
          textAlign: "center",
          padding: 0,
          background: "#d6433b",
          color: "#fff",
          fontFamily: "Open Sans",
          fontFamily: "Open Sans",
          fontWeight: 100,
          fontSize: 20,
          color: "#fff",
          textAlign: "center",
          display: "-webkit-box",
          display: "-ms-flexbox",
          display: "flex",
          webkitBoxPack: "center",
          msFlexPack: "center",
          justifyContent: "center",
          webkitBoxAlign: "center",
          msFlexAlign: "center",
          alignItems: "center",
        }}
      >
        <body
          style={{
            textAlign: "center",
            padding: 0,
            background: "#d6433b",
            color: "#fff",
            fontFamily: "Open Sans",
          }}
        >
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700"
            rel="stylesheet"
          />
          <article
            style={{
              display: "block",
              width: 700,
              padding: "50px",
              margin: "0 auto",
            }}
          >
            <svg
              style={{ width: 75, marginTop: "1em" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 202.24 202.24"
            >
              <title>Asset 3</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Capa_1" data-name="Capa 1" style={{ fill: "white" }}>
                  <path
                    style={{ fill: "white" }}
                    className="cls-1"
                    d="M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z"
                  />
                  <path
                    style={{ fill: "white" }}
                    className="cls-1"
                    d="M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z"
                  />
                </g>
              </g>
            </svg>
            <h1 style={{ fontSize: 50, fontWeight: 100, textAlign: "center" }}>
              We&rsquo;ll be back soon!
            </h1>
            <div>
              <p>
                Sorry for the inconvenience. We are performing some maintenance
                at the moment.
              </p>
            </div>
          </article>
        </body>
      </html>
    );
  } else {
    return (
      <>
        <div className="header">
          <div className="container">
            <div className="header_inside">
              <span className="helpline">
                <div className="help_line">
                  <h4>
                    {" "}
                    Call Now <b>{Settings && Settings.contact_no}</b>{" "}
                  </h4>
                </div>
              </span>
              <span>
                <div className="logo">
                  <Link to="/" onClick="window.location.reload(true)">
                    {Settings.logo ? (
                      <img src={Settings && Settings.logo} alt="Logo" />
                    ) : (
                      <img
                        style={{ width: "25px", hight: "25px" }}
                        src={emtyLogo}
                        alt="Logo"
                      />
                    )}
                  </Link>
                </div>
              </span>
              <span>
                {(() => {
                  if (Settings.shop_account_status == "enabled") {
                    return (
                      <div className="head_menus">
                        <ul>
                          <li className="hideSearch">
                            <img className="search_btns" src={Search} alt="" />
                            <img
                              style={{ display: "none" }}
                              className="search_btns1"
                              src={Search1}
                              alt=""
                            />
                          </li>
                          <li>
                          {Settings?.plan?.disable_cart_and_checkout == "no" ? (
                            <div
                              className="cartImg_outer"
                              onClick={() => CheckoutModelAction()}
                            >
                              <b>{Cartitems && Cartitems.length}</b>
                              <img id="card_black" src={Cart} alt="" />
                              <img
                                id="card_red"
                                style={{ display: "none" }}
                                src={Cartred}
                                alt=""
                              />
                            </div>
                            ) : (
                              ""
                            )}
                          </li>
                          <li>
                            <img className="openSidebar" src={Bars} alt="" />
                            <i
                              style={{
                                display: "none",
                                width: "13px",
                                marginLeft: "20px",
                                color: "#e84241",
                                fontSize: "16px",
                              }}
                              className="fa-solid fa-xmark closeSidebar"
                            ></i>
                          </li>
                        </ul>
                      </div>
                    );
                  }
                })()}
              </span>
            </div>
          </div>
          <div className="search_filter" style={{ display: "none" }}>
            <div className="container">
              <div className="filter_form">
                <button
                  id="filter_close"
                  className="modal_cancel"
                  style={{ fontSize: "14px", marginLeft: "25px" }}
                  type="button"
                  onClick={() => closeSearchCategory()}
                >
                  <span className="uparrowGray">
                    {" "}
                    <img src={Uparrow} /> Close{" "}
                  </span>
                  <span className="uparrowRed" style={{ display: "none" }}>
                    {" "}
                    <img src={UparrowR} /> Close{" "}
                  </span>
                </button>
                <div className="row justify-content-center">
                  <div className="col-md-9 col-lg-9">
                    <Form onSubmit={() => handleCategoryIdClick()}>
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-xs-5 paddR5">
                          <Form.Group controlId="">
                            <div className="inputOuter">
                              <Form.Control
                                type="text"
                                id="search_input"
                                placeholder="Search"
                                onChange={(e) => {
                                  setordermobile(e.target.value);
                                  handlesearch();
                                }}
                              />
                              {(() => {
                                if (ordermobile !== "") {
                                  return (
                                    <button id="clearBtn" type="button">
                                      {" "}
                                      <img
                                        src={Cancel}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.src = HoverCancel)
                                        }
                                        onMouseOut={(e) =>
                                          (e.currentTarget.src = Cancel)
                                        }
                                      />{" "}
                                    </button>
                                  );
                                }
                              })()}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xs-4 paddR0 paddL0">
                          <Form.Select
                            id="Category"
                            onChange={(e) => {
                              setSelectCategory(e.target.value);
                            }}
                          >
                            <option
                              value="all"
                              onClick={() => setSelectCategory("all")}
                            >
                              All
                            </option>
                            ,{" "}
                            {category &&
                              category.map((data, key) => (
                                <option
                                  value={data?.id}
                                  onClick={() => setSelectCategory(data.id)}
                                >
                                  {" "}
                                  {data && data.category_name}{" "}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                        <div className="col-md-2 col-lg-2 col-xs-3 paddL5">
                          <Button
                            variant="primary"
                            className="submitBtns"
                            onClick={() => handlesearch(data)}
                          >
                            {" "}
                            <img src={Search2} alt="" />{" "}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={Settings.heroBanner} className="img-fluid hero_image" />
        </div>
        <div className="container">
          <div>
            <div className="banner_content">
              <span>
                <h3>{Settings && Settings.shop_name} </h3>
                <p> {Settings && Settings.address}</p>
              </span>
              {Settings && Settings.shop_location != null && (
                <span>
                  {" "}
                  <a target="_blank" onClick={handleDirectionToShop}>
                    <button type="button">
                      <img src={Ico1} alt="" /> Get Direction{" "}
                    </button>
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="double_pics">
          <div className="container">
            <div className="deskDouble_slide" style={{ display: "block" }}>
              <Swiper
                // spaceBetween={18}
                spaceBetween={25}
                loop={true}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 1,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1400: {
                    slidesPerView: 2,
                  },
                }}
                modules={[Navigation]}
                navigation
                autoplay={{
                  delay: 500,
                  disableOnInteraction: true,
                }}
                className="mySwiper"
              >
                {Object.keys(Banner).map((BannerData) => (
                  <SwiperSlide>
                    <div className="work_slider">
                      <div className="pics_outer">
                        <img src={Banner[BannerData]} alt="" />{" "}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="howIt_works">
          <div className="container">
            <div className="works_slidedata">
              <Swiper
                spaceBetween={5}
                breakpoints={{
                  300: {
                    width: 350,
                    slidesPerView: 2,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 1,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  992: {
                    width: 992,
                    slidesPerView: 3,
                  },
                  1400: {
                    width: 1400,
                    slidesPerView: 4,
                  },
                }}
                modules={[Navigation]}
                navigation
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="work_slider">
                    <span>
                      {" "}
                      <img
                        src={`/admin/images/shopsettings/infogram/${
                          SettingsInfoLogo && SettingsInfoLogo.infogram_photo1
                        }`}
                        alt=""
                      />{" "}
                    </span>
                    <span>
                      <h4>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_name1_en) ||
                          (Settingsarab && Settingsarab.infogram_name1_ar)}
                      </h4>
                      <p>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_desc1_en) ||
                          (Settingsarab && Settingsarab.infogram_desc1_ar)}
                      </p>
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="work_slider">
                    <span>
                      {" "}
                      <img
                        src={`/admin/images/shopsettings/infogram/${
                          SettingsInfoLogo && SettingsInfoLogo.infogram_photo2
                        }`}
                        alt=""
                      />{" "}
                    </span>
                    <span>
                      <h4>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_name2_en) ||
                          (Settingsarab && Settingsarab.infogram_name2_ar)}
                      </h4>
                      <p>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_desc2_en) ||
                          (Settingsarab && Settingsarab.infogram_desc2_ar)}
                      </p>
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="work_slider">
                    <span>
                      {" "}
                      <img
                        src={`/admin/images/shopsettings/infogram/${
                          SettingsInfoLogo && SettingsInfoLogo.infogram_photo3
                        }`}
                        alt=""
                      />{" "}
                    </span>
                    <span>
                      <h4>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_name3_en) ||
                          (Settingsarab && Settingsarab.infogram_name3_ar)}
                      </h4>
                      <p>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_desc3_en) ||
                          (Settingsarab && Settingsarab.infogram_desc3_ar)}
                      </p>
                    </span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="work_slider">
                    <span>
                      {" "}
                      <img
                        src={`/admin/images/shopsettings/infogram/${
                          SettingsInfoLogo && SettingsInfoLogo.infogram_photo4
                        }`}
                        alt=""
                      />{" "}
                    </span>
                    <span>
                      <h4>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_name4_en) ||
                          (Settingsarab && Settingsarab.infogram_name4_ar)}
                      </h4>
                      <p>
                        {(Settingsenglish &&
                          Settingsenglish.infogram_desc4_en) ||
                          Settingsarab.infogram_desc4_ar}
                      </p>
                    </span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="works_inside">
              <ul>
                <li>
                  <span>
                    {" "}
                    <img
                      src={`/admin/images/shopsettings/infogram/${
                        SettingsInfoLogo && SettingsInfoLogo.infogram_photo1
                      }`}
                      alt=""
                    />{" "}
                  </span>
                  <span>
                    <h4>
                      {(Settingsenglish && Settingsenglish.infogram_name1_en) ||
                        (Settingsarab && Settingsarab.infogram_name1_ar)}
                    </h4>
                    <p>
                      {(Settingsenglish && Settingsenglish.infogram_desc1_en) ||
                        (Settingsarab && Settingsarab.infogram_desc1_ar)}
                    </p>
                  </span>
                </li>

                <li>
                  <span>
                    {" "}
                    <img
                      src={`/admin/images/shopsettings/infogram/${
                        SettingsInfoLogo && SettingsInfoLogo.infogram_photo2
                      }`}
                      alt=""
                    />{" "}
                  </span>
                  <span>
                    <h4>
                      {(Settingsenglish && Settingsenglish.infogram_name2_en) ||
                        (Settingsarab && Settingsarab.infogram_name2_ar)}
                    </h4>
                    <p>
                      {(Settingsenglish && Settingsenglish.infogram_desc2_en) ||
                        (Settingsarab && Settingsarab.infogram_desc2_ar)}
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    <img
                      src={`/admin/images/shopsettings/infogram/${
                        SettingsInfoLogo && SettingsInfoLogo.infogram_photo3
                      }`}
                      alt=""
                    />{" "}
                  </span>
                  <span>
                    <h4>
                      {(Settingsenglish && Settingsenglish.infogram_name3_en) ||
                        (Settingsarab && Settingsarab.infogram_name3_ar)}
                    </h4>
                    <p>
                      {(Settingsenglish && Settingsenglish.infogram_desc3_en) ||
                        (Settingsarab && Settingsarab.infogram_desc3_ar)}
                    </p>
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    <img
                      src={`/admin/images/shopsettings/infogram/${
                        SettingsInfoLogo && SettingsInfoLogo.infogram_photo4
                      }`}
                      alt=""
                    />{" "}
                  </span>
                  <span>
                    <h4>
                      {(Settingsenglish && Settingsenglish.infogram_name4_en) ||
                        (Settingsarab && Settingsarab.infogram_name4_ar)}
                    </h4>
                    <p>
                      {(Settingsenglish && Settingsenglish.infogram_desc4_en) ||
                        (Settingsarab && Settingsarab.infogram_desc4_ar)}
                    </p>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="products_sec" id="products">
          <div className="container">
            {(() => {
              if (Settings.shop_account_status == "enabled") {
                return (
                  <React.Fragment>
                    <Tabs
                      defaultActiveKey="shop"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="shop" title="Shop">
                        <div>
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-md-2 all-btn-none">
                              {selectButtons && selectButtons === "all" ? (
                                <div className="all_category mb-5">
                                  <Button
                                    type="button"
                                    onClick={() => getAllFunction()}
                                  >
                                    {" "}
                                    All{" "}
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  {" "}
                                  <Button
                                    type="button"
                                    style={{
                                      fontSize: "12px",
                                      width: "100%",
                                      outline: "non",
                                      textTransform: "uppercase",
                                      fontWeight: "500",
                                      color: "",
                                      b: "black",
                                    }}
                                    onClick={() => getAllFunction()}
                                  >
                                    {" "}
                                    All{" "}
                                  </Button>{" "}
                                </div>
                              )}
                            </div>

                            <div
                              className="col-md-10 "
                              style={{ paddingLeft: "0px" }}
                            >
                              <div className="slideData">
                                <Swiper
                                  spaceBetween={10}
                                  slidesPerView={4}
                                  modules={[Navigation]}
                                  navigation={true}
                                  className="mySwiper"
                                >
                                  {(() => {
                                    if (
                                      Settings.shop_account_status == "disabled"
                                    ) {
                                      return (
                                        <div>
                                          <div>
                                            <p></p>
                                          </div>
                                          <p style={{ marginLeft: "50px" }}>
                                            {Settings.disable_message}
                                          </p>
                                        </div>
                                      );
                                    } else if (bData.length > 0) {
                                      return category.map((data) => {
                                        return (
                                          <SwiperSlide
                                            onClick={() =>
                                              handleCategoryIdClick(data)
                                            }
                                            className={"swap-" + data.id}
                                          >
                                            <span className="col-2">
                                              <i className="">
                                                {" "}
                                                <img
                                                  src={data && data.icon}
                                                  alt=""
                                                />
                                              </i>
                                              <p style={{ fontSize: "14px" }}>
                                                {data && data.category_name}
                                              </p>
                                            </span>
                                          </SwiperSlide>
                                        );
                                      });
                                    }
                                  })()}
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="shops_sec" id="shops_sec">
                          <div className="shop_filter">
                            <div className="row">
                              <div className="col-md-7 col-lg-7"></div>
                              <div className="col-md-6 col-lg-5">
                                <ul>
                                  <li>
                                    <select
                                      onChange={(e) =>
                                        handleFilter(e.target.value)
                                      }
                                      className="form-control"
                                    >
                                      <option selected disabled>
                                        {" "}
                                        Filter by{" "}
                                      </option>

                                      <option value="PriceLowtoHigh">
                                        {" "}
                                        Price Low to High
                                      </option>
                                      <option value="PriceHightoLow">
                                        {" "}
                                        Price High to Low
                                      </option>
                                    </select>
                                  </li>

                                  <li>
                                    <Link to="" id="listview_btn">
                                      <img
                                        id="listGrayBtn"
                                        className="listGrayBtn"
                                        src={Filtr_list1}
                                        onMouseOver={listViewRed}
                                        onMouseOut={listViewGray}
                                      />
                                      <img
                                        id="listRedBtn"
                                        style={{ display: "none" }}
                                        className="listGrayBtn"
                                        src={Filtr_list}
                                        onMouseOver={listViewGray}
                                        onMouseOut={listViewRed}
                                      />
                                    </Link>
                                  </li>
                                  <li className="active">
                                    <Link to="" id="gridview_btn">
                                      <img
                                        id="graidRedBtn"
                                        className="gridDarkBtn"
                                        src={Filtr_grid}
                                        onMouseOver={gridViewGray}
                                        onMouseOut={gridViewRed}
                                      />
                                      <img
                                        id="graidGrayBtn"
                                        style={{ display: "none" }}
                                        className="gridDarkBtn"
                                        src={Filtr_grid1}
                                        onMouseOver={gridViewRed}
                                        onMouseOut={gridViewGray}
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <section id="engLang_sec">
                            <section id="grid_view">
                              <div className="row">
                                {(() => {
                                  if (bData.length > 0) {
                                    return bData.map((data) => (
                                      <div className="col-md-3 col-lg-3 width50 padR5">
                                        <div className="shopcard_outer">
                                          <div className="shopcard_pics">
                                            <Link
                                              to=""
                                              onClick={() =>
                                                handleEditClick(data)
                                              }
                                            >
                                              <div
                                                className={
                                                  data.badge_name
                                                    ? "product_badge"
                                                    : ""
                                                }
                                                style={{
                                                  backgroundColor: data.badge_colour,
                                                  paddingBottom: "2px",
                                                  color: isColorLight(data.badge_colour) ? "black" : "white",
                                                }}
                                              >
                                                {data.badge_name
                                                  ? data.badge_name
                                                  : ""}{" "}
                                              </div>
                                              <img
                                                src={data && data.images[0]}
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                          {/* </Link> */}

                                          <div className="shopcard_data">
                                            <Link
                                              to=""
                                              onClick={() =>
                                                handleEditClick(data)
                                              }
                                            >
                                              <h3>
                                                {" "}
                                                {data && data.product_name}{" "}
                                              </h3>
                                            </Link>
                                            <p className="text-truncate-2lines"
                                            dangerouslySetInnerHTML={{
                                              __html: data.description
                                              }}
                                            />
                                            {" "}
                                            <div style={{ display: "flex" }}>
                                              <h1>
                                                {Settings &&
                                                  Settings.currency.code}{" "}
                                                {data &&
                                                  data.sales_price?.toFixed(2)}
                                              </h1>
                                              <h1
                                                onClick={() =>
                                                  handleAddToCartClickIcon(data)
                                                }
                                                style={{ marginLeft: "auto" }}
                                              >
                                                {Settings?.plan?.disable_cart_and_checkout == "no" ? (
                                                <div className="shopcard_icon">
                                                  <img
                                                    src={Icon2}
                                                    alt=""
                                                    onMouseOver={over}
                                                    onMouseOut={out}
                                                  />{" "}
                                                </div>
                                                ) : (
                                                  ""
                                                  )}
                                              </h1>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ));
                                  } else if (
                                    Settings.shop_account_status == "disabled"
                                  ) {
                                    return (
                                      <div>
                                        <div>
                                          <p></p>
                                        </div>
                                        <p style={{ marginLeft: "50px" }}>
                                          {Settings.disable_message}
                                        </p>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <img
                                        src={NotProductFound}
                                        style={{
                                          width: "850px",
                                          hight: "800px",
                                          display: "block",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                        }}
                                        alt=""
                                      />
                                    );
                                  }
                                })()}
                              </div>
                            </section>
                            <section id="list_view" style={{ display: "none" }}>
                              <div className="row">
                                {bData ? (
                                  bData.map((data) => (
                                    <div className="col-md-6 col-lg-6 mt-2">
                                      <div className="grid_card">
                                        <Link
                                          to=""
                                          onClick={() => handleEditClick(data)}
                                        >
                                          <span>
                                            <img
                                              src={data && data.images[0]}
                                              alt=""
                                            />{" "}
                                          </span>
                                        </Link>

                                        <span className="prod_box_content">
                                          <Link
                                            to=""
                                            onClick={() =>
                                              handleEditClick(data)
                                            }
                                          >
                                            <h5 style={{ color: "#212121" }}>
                                              {data && data.product_name}{" "}
                                            </h5>
                                          </Link>
                                          <p>
                                            {" "}
                                            <b> </b>
                                          </p>
                                          {Settings && Settings.currency.code}{" "}
                                          {data && data.sales_price?.toFixed(2)}
                                          {Settings?.plan?.disable_cart_and_checkout == "no" ? (
                                          <h1
                                            onClick={() =>
                                              handleAddToCartClickIcon(data)
                                            }
                                          >
                                            <img
                                              className="bigarrow"
                                              src={Bigico}
                                              style={{ width: "20px" }}
                                              alt=""
                                              onMouseOver={over}
                                              onMouseOut={out}
                                            />
                                          </h1>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <img
                                    src={NotProductFound}
                                    style={{
                                      width: "850px",
                                      hight: "800px",
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                    alt=""
                                  />
                                )}
                              </div>
                            </section>
                          </section>
                        </div>

                        <div className="resp_footer">
                          <div className="container">
                            <div className="respfoot_inside">
                              <ul>
                                <li
                                  id="myCategoryBtn"
                                  onClick={() => openCategoryModel()}
                                >
                                  <img
                                    id="icoFootGrayBtn"
                                    src={Icofoot1}
                                    alt=""
                                  />
                                  <img
                                    id="icoFootRedBtn"
                                    style={{ display: "none" }}
                                    src={Icofoot11}
                                    alt=""
                                  />
                                  Categories
                                </li>
                                <li>
                                  <span
                                    className="btnGridview"
                                    style={{ display: "none", color: "#f00" }}
                                  >
                                    <img src={Icofoot22} alt="" />
                                    View
                                  </span>
                                  <span className="btnListview">
                                    <img src={Icofoot2} alt="" />
                                    View
                                  </span>
                                </li>
                                <li
                                  id="filtersBtns"
                                  onClick={() => openFilterModel()}
                                >
                                  <img
                                    id="fiterGrayBtn"
                                    src={Icofoot3}
                                    alt=""
                                    onMouseOver={icoFootRed}
                                    onMouseOut={icoFootGray}
                                  />
                                  <img
                                    id="fiterRedBtn"
                                    style={{ display: "none" }}
                                    src={Icofoot33}
                                    alt=""
                                    onMouseOver={icoFootGray}
                                    onMouseOut={icoFootRed}
                                  />
                                  Filters
                                </li>
                                <li id="showSearchbar">
                                  <img src={Icofoot4} alt="" />
                                  Search
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="about" title="About Us">
                        <div className="about_section">
                          <div className="about_card">
                            <h6> Who We Are </h6>
                            <p> </p>
                            <p>{AboutUs && AboutUs.about}</p>
                          </div>
                          <div
                            className="about_card"
                            style={{ marginTop: "50px" }}
                          >
                            <h6> What We do ? </h6>
                            <p> {AboutUs && AboutUs.about}</p>
                          </div>
                        </div>

                        <div className="lastmenus">
                          <div className="container">
                            <ul>
                              <li className="btnShopSec boldWeight">
                                <img
                                  className="shopIcoGray"
                                  src={ShopFoot}
                                  alt=""
                                />
                                <img
                                  className="shopIcoRed"
                                  style={{ display: "none" }}
                                  src={ShopFoot1}
                                  alt=""
                                />
                                Shop
                              </li>
                              <li className="btnAboutSec">
                                <img
                                  className="aboutIcoGray"
                                  src={AboutusFoot}
                                  alt=""
                                />
                                <img
                                  className="aboutIcoRed"
                                  style={{ display: "none" }}
                                  src={AboutusFoot1}
                                  alt=""
                                />
                                About Us
                              </li>
                              <li className="btnContactSec">
                                <img
                                  className="contactIcoGray"
                                  src={ContactusFoot}
                                  alt=""
                                />
                                <img
                                  className="contactIcoRed"
                                  style={{ display: "none" }}
                                  src={ContactusFoot1}
                                  alt=""
                                />
                                Contact Us
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="contact" title="Contact">
                        <div className="contact_sec">
                          <div className="row">
                            <div className="col-md-5 col-lg-5">
                              <div className="address_slot">
                                <ul>
                                  <li>
                                    <span className="span_label">
                                      {" "}
                                      <label> Address: </label>{" "}
                                    </span>
                                    <span>
                                      <p> {Settings && Settings.address} </p>
                                    </span>
                                  </li>
                                  <li>
                                    <span className="span_label">
                                      {" "}
                                      <label> Email: </label>{" "}
                                    </span>
                                    <span>
                                      <p> {Settings && Settings.email} </p>
                                    </span>
                                  </li>
                                  <li>
                                    <span className="span_label">
                                      {" "}
                                      <label> Phone: </label>{" "}
                                    </span>
                                    <span>
                                      <p>{Settings && Settings.contact_no} </p>
                                      <p>{Settings && Settings.contact_no1}</p>
                                    </span>
                                  </li>
                                </ul>
                              </div>

                              <div className="address_slot hours_slote">
                                <label> Business Hours: </label>
                                <ul>
                                  <li>{Settings && Settings.business_hours}</li>
                                  {/* <li>Saturday : 8.00 to 14.00</li>
                        <li>Sunday: Closed</li> */}
                                </ul>
                              </div>

                              <div className="address_slot social_ico">
                                <label> SOCIAL MEDIA: </label>
                                <ul>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={`${
                                        Settings &&
                                        Settings.social_links.facebook_url
                                      }`}
                                    >
                                      {Settings &&
                                      Settings.social_links.facebook_url ===
                                        null ? null : (
                                        <img
                                          src={Social1}
                                          onMouseOver={imgFacebookR}
                                          onMouseOut={imgFacebookG}
                                        />
                                      )}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={`${
                                        Settings &&
                                        Settings.social_links.instagram_url
                                      }`}
                                    >
                                      {Settings &&
                                      Settings.social_links.instagram_url ===
                                        null ? null : (
                                        <img
                                          src={Social2}
                                          onMouseOver={imgInstaR}
                                          onMouseOut={imgInstaG}
                                        />
                                      )}
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      target="_blank"
                                      href={`${
                                        Settings &&
                                        Settings.social_links.twitter_url
                                      }`}
                                    >
                                      {Settings &&
                                      Settings.social_links.twitter_url ===
                                        null ? null : (
                                        <img
                                          src={Social4}
                                          onMouseOver={imgTweeterAppR}
                                          onMouseOut={imgTwitterAppG}
                                        />
                                      )}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={`${
                                        Settings &&
                                        Settings.social_links.youtube_url
                                      }`}
                                    >
                                      {Settings &&
                                      Settings.social_links.youtube_url ===
                                        null ? null : (
                                        <img
                                          src={Social5}
                                          onMouseOver={imgYoutubAppR}
                                          onMouseOut={imgYoutubAppG}
                                        />
                                      )}
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      target="_blank"
                                      href={`${
                                        Settings &&
                                        Settings.social_links.snapchat_url
                                      }`}
                                    >
                                      {Settings &&
                                      Settings.social_links.snapchat_url ==
                                        null ? null : (
                                        <img
                                          src={Social6}
                                          onMouseOver={imgSnapAppR}
                                          onMouseOut={imgSnapAppG}
                                        />
                                      )}
                                    </a>
                                  </li>
                                  <li></li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-7 col-lg-7">
                              <h5> WRITE TO US: </h5>
                              <Form onSubmit={handleSubmit1(PostContactUs)}>
                                <div className="form-group">
                                  <Controller
                                    control={control1}
                                    id="first_name"
                                    name="first_name"
                                    render={({ field }) => (
                                      <input
                                        type="text"
                                        maxLength={50}
                                        placeholder="Firstname "
                                        className="form-control"
                                        invalid={errors.first_name && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors && errors.first_name && (
                                    <FormFeedback>
                                      Please enter a first_name
                                    </FormFeedback>
                                  )}
                                </div>
                                <div className="form-group">
                                  <Controller
                                    control={control1}
                                    id="last_name"
                                    name="last_name"
                                    render={({ field }) => (
                                      <input
                                        type="text"
                                        maxLength={50}
                                        placeholder="Lastname "
                                        className="form-control"
                                        invalid={errors.last_name && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors && errors.last_name && (
                                    <FormFeedback>
                                      Please enter a last_name
                                    </FormFeedback>
                                  )}
                                </div>
                                <div className="form-group">
                                  {/* <Controller
                                    control={control1}
                                    id="email"
                                    name="email"
                                    render={({ field }) => (
                                      <input
                                        type="email"
                                        maxLength={50}
                                        placeholder="Email "
                                        className="form-control"
                                        invalid={errors.email && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors && errors.email && (
                                    <FormFeedback>
                                      Please enter a email
                                    </FormFeedback>
                                  )} */}
                                </div>
                                <div className="form-group">
                                  <Controller
                                    control={control1}
                                    id="message"
                                    name="message"
                                    render={({ field }) => (
                                      <textarea
                                        type="text"
                                        maxLength={50}
                                        placeholder="Message "
                                        className="form-control"
                                        invalid={errors.message && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors && errors.message && (
                                    <FormFeedback>
                                      Please enter a message
                                    </FormFeedback>
                                  )}
                                </div>
                                <Button type="submit"> Send </Button>
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div className="lastmenus">
                          <div className="container">
                            <ul>
                              <li className="btnShopSec boldWeight">
                                <img
                                  className="shopIcoGray"
                                  src={ShopFoot}
                                  alt=""
                                />
                                <img
                                  className="shopIcoRed"
                                  style={{ display: "none" }}
                                  src={ShopFoot1}
                                  alt=""
                                />
                                Shop
                              </li>
                              <li className="btnAboutSec">
                                <img
                                  className="aboutIcoGray"
                                  src={AboutusFoot}
                                  alt=""
                                />
                                <img
                                  className="aboutIcoRed"
                                  style={{ display: "none" }}
                                  src={AboutusFoot1}
                                  alt=""
                                />
                                About Us
                              </li>
                              <li className="btnContactSec">
                                <img
                                  className="contactIcoGray"
                                  src={ContactusFoot}
                                  alt=""
                                />
                                <img
                                  className="contactIcoRed"
                                  style={{ display: "none" }}
                                  src={ContactusFoot1}
                                  alt=""
                                />
                                Contact Us
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          padding: "10px 10px 1px 10px",
                          background: "#e8e7e7",
                          textAlign: "center",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#655454",
                        }}
                      >
                        <p>Shop Notification</p>
                      </div>
                      <p
                        style={{
                          marginTop: "61px",
                          padding: "0",
                          color: "red",
                          textAlign: "center",
                        }}
                      >
                        {Settings.disable_message}
                      </p>
                    </div>
                  </React.Fragment>
                );
              }
            })()}
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="footer_inside">
              <span>
                {" "}
                <img src={Footlogo} alt="" />{" "}
              </span>
              <span>
                <h6> © 2023 Shoprr.app All rights reserved </h6>
              </span>
              <span>
                <div className="footmenu">
                  <ul>
                    <li>
                      {localStorage.getItem("Arab") === null ? (
                        <>
                          <img
                            onClick={ArabLanguage}
                            id="langArabic"
                            src={LangB}
                            onMouseOver={languageRed}
                            onMouseOut={languageGray}
                            alt="langArabic"
                          />
                          <img
                            onClick={EnglishLanguage}
                            style={{ display: "none" }}
                            id="langEnglish"
                            src={LangR}
                            onMouseOver={languageGray}
                            onMouseOut={languageRed}
                            alt="langEnglish"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            onClick={ArabLanguage}
                            style={{ display: "none" }}
                            id="langArabic"
                            src={LangB}
                            onMouseOver={languageRed}
                            onMouseOut={languageGray}
                            alt="langArabic"
                          />
                          <img
                            onClick={EnglishLanguage}
                            id="langEnglish"
                            src={LangR}
                            onMouseOver={languageGray}
                            onMouseOut={languageRed}
                            alt="langEnglish"
                          />
                        </>
                      )}
                    </li>
                    <li>
                      <img
                        id="mytrack"
                        onClick={() => OpenTrackOrder()}
                        src={Icon4}
                        onMouseOver={trackRed}
                        onMouseOut={trackGray}
                        alt=""
                      />
                      <img
                        id="mytrackRed"
                        style={{ display: "none" }}
                        onClick={() => OpenTrackOrder()}
                        src={Icon42}
                        onMouseOver={trackGray}
                        onMouseOut={trackRed}
                        alt=""
                      />
                    </li>

                    <li></li>
                  </ul>
                </div>
              </span>
            </div>
          </div>
        </div>

        <a className="gotTop" style={{ display: "none" }}>
          <label for="ajay">
            <img
              id="ajay"
              src={Uparrow}
              onMouseOver={upArrowRed}
              onMouseOut={upArrowGray}
            />
            <span> Top </span>
          </label>
        </a>

        <div className="sidebar_sec">
          <div className="container">
            <div className="sidebar_menus">
              <ul>
                <li className="btnShopSec">
                  <img id="imgShopG" src={Side1} />
                  <img id="imgShopR" style={{ display: "none" }} src={Side11} />
                  Shop
                </li>
                <li className="btnAboutSec">
                  <img id="imgAboutG" src={Side2} />
                  <img
                    id="imgAboutR"
                    style={{ display: "none" }}
                    src={Side22}
                  />
                  About Us
                </li>
                <li className="btnContactSec">
                  <img id="imgContactG" src={Side3} />
                  <img
                    id="imgContactR"
                    style={{ display: "none" }}
                    src={Side33}
                  />
                  Contact
                </li>
                <li id="sidetrack_ord" onClick={() => OpenTrackOrder()}>
                  <img src={Side4} />
                  Track Order
                </li>
              </ul>
            </div>
            <div className="side_lang">
              <p>
                {localStorage.getItem("Arab") === null ? (
                  <>
                    <span
                      id="langArabic1"
                      onClick={ArabLanguage}
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <img src={LangB} alt="" /> English{" "}
                    </span>
                    <span
                      id="langEnglish1"
                      onClick={EnglishLanguage}
                      style={{
                        display: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      <img src={LangR} alt="" /> Arabic{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      id="langArabic1"
                      onClick={ArabLanguage}
                      style={{
                        display: "none",
                        fontWeight: "bold",
                      }}
                    >
                      <img src={LangB} alt="" /> English{" "}
                    </span>
                    <span
                      id="langEnglish1"
                      onClick={EnglishLanguage}
                      style={{
                        color: "#E84242",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      <img src={LangR} alt="" /> Arabic{" "}
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className="side_social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    href={`${Settings && Settings.social_links.facebook_url}`}
                  >
                    {Settings &&
                    Settings.social_links.facebook_url === null ? null : (
                      <img
                        src={Social1}
                        onMouseOver={imgFacebookR}
                        onMouseOut={imgFacebookG}
                      />
                    )}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`${Settings && Settings.social_links.instagram_url}`}
                  >
                    {Settings &&
                    Settings.social_links.instagram_url === null ? null : (
                      <img
                        src={Social2}
                        onMouseOver={imgInstaR}
                        onMouseOut={imgInstaG}
                      />
                    )}
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href={`${Settings && Settings.social_links.twitter_url}`}
                  >
                    {Settings &&
                    Settings.social_links.twitter_url === null ? null : (
                      <img
                        src={Social4}
                        onMouseOver={imgTweeterAppR}
                        onMouseOut={imgTwitterAppG}
                      />
                    )}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`${Settings && Settings.social_links.youtube_url}`}
                  >
                    {Settings &&
                    Settings.social_links.youtube_url === null ? null : (
                      <img
                        src={Social5}
                        onMouseOver={imgYoutubAppR}
                        onMouseOut={imgYoutubAppG}
                      />
                    )}
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href={`${Settings && Settings.social_links.snapchat_url}`}
                  >
                    {Settings &&
                    Settings.social_links.snapchat_url == null ? null : (
                      <img
                        src={Social6}
                        onMouseOver={imgSnapAppR}
                        onMouseOut={imgSnapAppG}
                      />
                    )}
                  </a>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="sidedown">
              <h6>
                {" "}
                Call Now{" "}
                <span>
                  {" "}
                  <b>{Settings && Settings.contact_no}</b>
                </span>{" "}
              </h6>
              <p> © 2023 shoprr.app. All rights reserved </p>
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          className="addtocard_modal minHeight100 addmyCaRD"
        >
          <Modal.Header closeButton style={{ height: "40px" }}>
            <div className="header_enter">
              <h4>{Data && Data.product_name}</h4>

              <ul className="slideImages">
                {countA == 0 ? (
                  <li></li>
                ) : (
                  <li onClick={handlePrevItem}>
                    <img className="modal_slide_left" src={Arrow_left} />
                    <img
                      style={{ display: "none" }}
                      className="modal_slide_leftR"
                      src={Arrow_leftR}
                    />
                  </li>
                )}

                {countA == bData.length - 1 ? (
                  <li></li>
                ) : (
                  <li onClick={handleNextItem}>
                    <img className="modal_slide_right" src={Arrow_right} />
                    <img
                      style={{ display: "none" }}
                      className="modal_slide_rightR"
                      src={Arrow_rightR}
                    />
                  </li>
                )}
              </ul>
            </div>
          </Modal.Header>

          <Modal.Body className="max_height120">
            <div className="myprod_sec">
              <div className="row">
                <div className="col-md-5">
                  <div className="prod_pic_outer">
                    <Swiper
                      spaceBetween={20}
                      slidesPerView={1}
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper2"
                    >
                      {Data &&
                        Data.images.map((dataImages, index) => {
                          return (
                            <SwiperSlide>
                              <div
                                className={
                                  Data.badge_name ? "product_badge" : ""
                                }
                                style={{
                                  backgroundColor: Data.badge_colour,
                                  marginRight: "32px",
                                  color: isColorLight(Data.badge_colour) ? "black" : "white",
                                }}
                              >
                                {Data.badge_name ? Data.badge_name : ""}{" "}
                              </div>
                              <img src={dataImages && dataImages} />
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </div>
                  <div className="multi_pics">
                    <ul>
                      {Data &&
                        Data.images.map((dataImages1, index) => {
                          return (
                            <li>
                              {" "}
                              <img src={dataImages1 && dataImages1} />
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="prod_content">
                    <h5>
                      {Data && Data.regular_price !== Data.sales_price && (
                        <span style={{ textDecoration: "line-through", marginRight: "8px", color: "red" }}>
                          {Settings && Settings.currency.code}
                          {regular_price_sum === 0 ? Data && Data.regular_price : regular_price_sum}
                        </span>
                      )}
                      {Data.product_type === "variable" ? (
                        <>
                          {Settings && Settings.currency.code}
                          {sum === 0 ? Data && Data.sales_price : sum}
                        </>
                      ) : (
                        <>
                          {Settings && Settings.currency.code}
                          {Data && Data.sales_price}
                        </>
                      )}
                    </h5>

                    <div className="my_option">
                      <div className="form-group">
                        {Object.keys(VariantData).map((variantName) => (
                          <div>
                            {variantName ? <h6> Select Options </h6> : null}

                            <lable>{variantName}</lable>

                            <ul className="custom_radio colorUL">
                              {VariantData[variantName].map((data) => (
                                <li
                                  onChange={(e) =>
                                    radioChangeVariant(e, data, variantName)
                                  }
                                >
                                  <label
                                    for={data && data.variant_id}
                                    className="myradio"
                                  >
                                    {" "}
                                    {data && data.variant_name}{" "}
                                    <b>
                                      {" "}
                                      {Settings && Settings.currency.code}{" "}
                                      {data && data.sales_price.toFixed(2)}{" "}
                                    </b>
                                    <input
                                      type="radio"
                                      key={data && data.variant_id}
                                      for={data && data.variant_id}
                                      name={`${variantName}`}
                                      id={data.variant_id}
                                      value={data.variant_id}
                                      defaultChecked={data.is_default === "yes"}
                                      // checked={data.is_default === 'yes'}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>

                      <div className="form-group">
                        <ul className="myExtra">
                          {DataAddons ? <h6> Select Extras </h6> : null}

                          {DataAddons &&
                            DataAddons.map((item) => (
                              <li>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleOnChange(item, e.target.checked)
                                  }
                                />
                                <label for="test12">
                                  <b
                                    className={isChecked(
                                      item && item.addon_name
                                    )}
                                  >
                                    {item && item.addon_name}{" "}
                                  </b>
                                  <span
                                    className={isChecked(item && item.price)}
                                  >
                                    {" "}
                                    <b>
                                      {" "}
                                      {Settings && Settings.currency.code}{" "}
                                      {item && item.price.toFixed(2)}
                                    </b>{" "}
                                  </span>{" "}
                                </label>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my_description">
                <h6> Description </h6>

                <div
                dangerouslySetInnerHTML={{
                  __html: Data.description
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <div className="four_letter">
            {sum === 0 ? (
              <>
                {Object.keys(VariantData).map((datas) =>
                  VariantData[datas].map((data) => (
                    <>
                      {data.is_default === "yes"
                        ? `${data.variant_name},`
                        : null}
                    </>
                  ))
                )}
              </>
            ) : (
              <>
                {Object.values(varName).map((Name) => (
                  <>{Name == null ? null : `${Name},`}</>
                ))}
              </>
            )}

            {Addname == null ? null : `${Addname}`}
          </div>
          <Modal.Footer>
            <div className="modalfoot_last">
              <div className="row">
                <div className="col-md-6 width25">
                {Settings?.plan?.disable_cart_and_checkout == "no" ? (
                  <div className="qtyli_sec">
                    <ul className="prod_qty">
                      <li onClick={incrementCount}>
                        <img
                          style={{
                            width: "13px",
                            height: "13px",
                            objectFit: "contain",
                          }}
                          src={PlusGray}
                          onMouseOver={imgPlusRed}
                          onMouseOut={imgPlusGray}
                        />
                      </li>
                      <li>{count}</li>
                      <li onClick={decrementCount}>
                        <img
                          style={{
                            width: "13px",
                            height: "13px",
                            objectFit: "contain",
                          }}
                          src={minusBG}
                          onMouseOver={minusBRed}
                          onMouseOut={minusBGray}
                        />
                      </li>
                    </ul>
                  </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="col-md-4 mobile-cart-section width60"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {Settings?.plan?.disable_cart_and_checkout == "no" ? (
                    Data.product_type === "variable" ? (
                      <h3>
                        {Settings && Settings.currency.code}
                        {sum === 0
                          ? count * Data.sales_price + TotalPriceAddons
                          : count * sum + TotalPriceAddons}
                      </h3>
                    ) : (
                      <h3>
                        {Settings && Settings.currency.code}
                        {count * Data.sales_price + sum + TotalPriceAddons}
                      </h3>
                    )
                  ) : (
                    ""
                  )}

                  <Link to="">
                    {" "}
                    {Settings?.plan?.disable_cart_and_checkout == "no" ? (
                    <Button
                      className="myborder"
                      type="button"
                      onClick={() => AddToCart(Data)}
                    >
                      <ToastContainer
                        position="top-center"
                        reverseOrder={false}
                      />
                      Add to Cart{" "}
                    </Button>
                    ) : (
                      ""
                      )}
                  </Link>
                </div>
                <div className="col-md-2 width15">
                  <button
                    className="bigmodal_lose"
                    style={{ fontSize: "14px", marginLeft: "25px" }}
                    type="button"
                    onClick={handleClose}
                  >
                    <span className="uparrowGray">
                      {" "}
                      <img src={Uparrow} /> Close{" "}
                    </span>
                    <span className="uparrowRed" style={{ display: "none" }}>
                      {" "}
                      <img src={UparrowR} /> Close{" "}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={checkoutshow}
          onHide={checkOutClose}
          size={"lg"}
          dialogClassName="modal-dialog-custom"
          className="ModelCustom checkoutModal"
        >
          <Modal.Body>
            <div className="checkout_sec">
              <div className="container">
                <section id="check_step1">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="scrollCart">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-8">
                              <label> Order Type </label>
                              <select className="form-control">
                                <option> Cash On Delivery </option>
                              </select>
                              <span className="labelDown">
                                {" "}
                                * We dont accept credit cards for cash on
                                delivery orders{" "}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="checkout_data">
                          {Cartitems &&
                            Cartitems.map((data) => {
                              return (
                                <div className="checkout_rows">
                                  <span
                                    className="crossImg"
                                    onClick={() => handleDeleteClick(data)}
                                  ></span>

                                  <span className="col-2">
                                    {" "}
                                    <img
                                      src={data && data.product_images[0]}
                                    />{" "}
                                  </span>

                                  <span
                                    className="checkOutName"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <h3>
                                      {data && data.product_name}{" "}
                                      <span>
                                        {" "}
                                        {data && data.variant_name ? (
                                          <>
                                            {" "}
                                            <b>- {data && data.variant_name}</b>
                                          </>
                                        ) : (
                                          <p></p>
                                        )}
                                      </span>
                                    </h3>
                                    <h3>
                                      {Settings && Settings.currency.code}{" "}
                                      {data.tax_amount.toFixed(2)}
                                    </h3>

                                    {data.extras.map((data1) => {
                                      if (localStorage.getItem("Arab")) {
                                        return (
                                          <>
                                            <p className="mr-2">
                                              {data1 && data1.addon_name_ar},
                                            </p>
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <h6
                                              className="mr-2"
                                              style={{
                                                display: "inline-block",
                                                fontSize: "0.7rem",
                                              }}
                                            >
                                              {" "}
                                              {data1 &&
                                                data1.addon_name_en},{" "}
                                            </h6>
                                          </>
                                        );
                                      }
                                    })}
                                  </span>

                                  <span style={{ width: "130px" }}>
                                    <ul className="prod_qty">
                                      <li
                                        onClick={() =>
                                          handleDecreamentQuantity(data)
                                        }
                                      >
                                        <img
                                          style={{
                                            width: "13px",
                                            height: "3px",
                                          }}
                                          src={minusBG}
                                          onMouseOver={minusBRed}
                                          onMouseOut={minusBGray}
                                        />
                                      </li>
                                      <li>{data && data.quantity}</li>

                                      <li
                                        onClick={() =>
                                          handleIncreamentQuantity(data)
                                        }
                                      >
                                        <img
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                          }}
                                          src={PlusGray}
                                          onMouseOver={imgPlusRed}
                                          onMouseOut={imgPlusGray}
                                        />
                                      </li>
                                    </ul>
                                  </span>

                                  <span
                                    className="mainAmount"
                                    style={{ width: "80px", fontSize: "20px" }}
                                  >
                                    {Settings && Settings.currency.code}{" "}
                                    {data.extras == null
                                      ? data.sales_price * data.quantity
                                      : data.extras.reduce(function (acc, obj) {
                                          return acc + obj.price;
                                        }, data.sales_price * data.quantity)}
                                    {/* {data.sales_price * data.quantity} */}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                        <div className="coupen_code">
                          <div className="row">
                            <div className="col-md-6 width60 ">
                              <label style={{ marginBottom: "0px" }}>
                                {" "}
                                Coupon Code{" "}
                              </label>
                              <input
                                style={{ lineHeight: "0" }}
                                type="text"
                                onChange={(e) => {
                                  setcouponcode(e.target.value);
                                }}
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-5 width25">
                              <Button
                                style={{ marginTop: "11px", marginLeft: "0" }}
                                className="next_btns"
                                type="button"
                                onClick={hendelcouponcode}
                              >
                                {" "}
                                Apply{" "}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="bottom_total">
                          <div className="row">
                            <div className="col-md-6 "></div>
                            <div className="col-md-6  mt-3">
                              <ul>
                                <li>
                                  {Cartitems == 0
                                    ? null
                                    : ` SubTotal :
     
                                  ${Settings && Settings.currency.code}  ${
                                        // CartitemsAmount && CartitemsAmount.sub_total - CartitemsAmount && CartitemsAmount.tax_amount

                                        CartitemsAmount.sub_total.toFixed(2)
                                        //  CartitemsAmount.tax_amount
                                      }`}
                                </li>
                                <li>
                                  {CouponId ? (
                                    <>
                                      Discount :{" "}
                                      {Settings && Settings.currency.code}
                                    </>
                                  ) : null}{" "}
                                  {CouponId && CouponId.discount_amount}
                                </li>
                                <li>
                                  {Cartitems == 0
                                    ? null
                                    : `Tax Amount :
                               ${Settings && Settings.currency.code} ${
                                        typeof CartitemsAmount.tax_amount ===
                                        "number"
                                          ? CartitemsAmount.tax_amount.toFixed(
                                              2
                                            )
                                          : CartitemsAmount &&
                                            CartitemsAmount.tax_amount
                                      }`}
                                </li>
                                {/* <li> Delivery Charge :  $ 5.00  </li> */}
                                <li></li>

                                <li>
                                  <b>
                                    {Cartitems == 0
                                      ? null
                                      : `Total Amount :
                                    ${Settings && Settings.currency.code} 
                                    ${
                                      CouponId
                                        ? typeof CouponId.total === "number"
                                          ? CouponId.total.toFixed(2)
                                          : CouponId.total
                                        : typeof CartitemsAmount.total ===
                                          "number"
                                        ? CartitemsAmount.total.toFixed(2)
                                        : CartitemsAmount &&
                                          CartitemsAmount.total
                                    }`}
                                  </b>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <section>
                        <div className="steps_wizard">
                          <div>
                            <ul>
                              <li className="muactive">
                                {" "}
                                <span> 1 </span>{" "}
                              </li>
                              <li>
                                {" "}
                                <span> 2 </span>{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <Form onSubmit={handleSubmit(saveCheckoutData)}>
                          <div className="step_form">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="customer_name"> Name </label>
                                  <Controller
                                    control={control}
                                    id="customer_name"
                                    name="customer_name"
                                    render={({ field }) => (
                                      <input
                                        type="text"
                                        maxLength={50}
                                        className="form-control"
                                        invalid={errors.customer_name && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors && errors.customer_name && (
                                    <FormFeedback>
                                      Please enter a customer_name
                                    </FormFeedback>
                                  )}
                                </div>
                              </div>

                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label for="email"> Email </label>
                                  <Controller
                                    control={control}
                                    id="email"
                                    name="email"
                                    render={({ field }) => (
                                      <input
                                        type="text"
                                        maxLength={50}
                                        className="form-control"
                                        invalid={errors.email && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors && errors.email && (
                                    <FormFeedback>
                                      Please enter a email
                                    </FormFeedback>
                                  )}
                                </div>
                              </div> */}
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="contact_no1">
                                    {" "}
                                    Phone Number{" "}
                                  </label>
                                  <Controller
                                    control={control}
                                    id="contact_no1"
                                    name="contact_no1"
                                    render={({ field }) => (
                                      <input
                                        type="text"
                                        maxLength={50}
                                        className="form-control"
                                        invalid={errors.contact_no1 && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors && errors.contact_no1 && (
                                    <FormFeedback>
                                      Please enter a contact_no1
                                    </FormFeedback>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="order_note"> Order Note </label>
                                  <Controller
                                    control={control}
                                    id="order_note"
                                    name="order_note"
                                    render={({ field }) => (
                                      <input
                                        type="text"
                                        maxLength={50}
                                        className="form-control"
                                        invalid={errors.order_note && true}
                                        {...field}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label for="manual_address">
                                    {" "}
                                    Address line 1{" "}
                                  </label>
                                  <Controller
                                    control={control}
                                    id="manual_address"
                                    name="manual_address"
                                    render={({ field }) => (
                                      <textarea
                                        maxLength={200}
                                        className="form-control"
                                        style={{ minHeight: "0" }}
                                        invalid={errors.manual_address && true}
                                        {...field}
                                      ></textarea>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="map_sec">
                              {/* <ControlPanel /> */}
                            </div>

                            <Button type="submit" className="next_btns">
                              {" "}
                              Next{" "}
                            </Button>
                          </div>
                        </Form>
                      </section>
                    </div>
                  </div>
                </section>

                <section id="check_step2" style={{ display: "none" }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="scrollCart">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-8">
                              <label> Order Type </label>
                              <select className="form-control" disabled>
                                <option> Cash On Delivery </option>
                              </select>
                              <span className="labelDown">
                                {" "}
                                * We dont accept credit cards for cash on
                                delivery orders{" "}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="checkout_data">
                          {Cartitems &&
                            Cartitems.map((data) => {
                              return (
                                <div className="checkout_rows">
                                  <span className="col-2">
                                    {" "}
                                    <img
                                      src={data && data.product_images[0]}
                                    />{" "}
                                  </span>

                                  <span
                                    className="checkOutName"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <h3>
                                      {data && data.product_name}{" "}
                                      <span>
                                        {" "}
                                        {data && data.variant_name ? (
                                          <>
                                            {" "}
                                            <b>- {data && data.variant_name}</b>
                                          </>
                                        ) : (
                                          <p></p>
                                        )}
                                      </span>
                                    </h3>
                                    <h3>
                                      {Settings && Settings.currency.code}{" "}
                                      {data.tax_amount.toFixed(2)}
                                    </h3>

                                    {data.extras.map((data1) => {
                                      if (localStorage.getItem("Arab")) {
                                        return (
                                          <>
                                            <p className="mr-2">
                                              {data1 && data1.addon_name_ar},
                                            </p>
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <h6
                                              className="mr-2"
                                              style={{
                                                display: "inline-block",
                                                fontSize: "0.7rem",
                                              }}
                                            >
                                              {" "}
                                              {data1 &&
                                                data1.addon_name_en},{" "}
                                            </h6>
                                          </>
                                        );
                                      }
                                    })}
                                  </span>

                                  <span style={{ width: "130px" }}>
                                    <ul className="prod_qty">
                                      <li>{data && data.quantity}</li>
                                    </ul>
                                  </span>

                                  <span
                                    className="mainAmount"
                                    style={{ width: "80px", fontSize: "20px" }}
                                  >
                                    {Settings && Settings.currency.code}{" "}
                                    {data.extras == null
                                      ? data.sales_price * data.quantity
                                      : data.extras.reduce(function (acc, obj) {
                                          return acc + obj.price;
                                        }, data.sales_price * data.quantity)}
                                    {/* {data.sales_price * data.quantity} */}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                        <div className="coupen_code">
                          <div className="row">
                            <div className="col-md-6 width60 ">
                              <label style={{ marginBottom: "0px" }}>
                                {" "}
                                Coupon Code{" "}
                              </label>
                              <input
                                style={{ lineHeight: "0" }}
                                type="text"
                                onChange={(e) => {
                                  setcouponcode(e.target.value);
                                }}
                                className="form-control"
                                disabled
                              />
                            </div>
                            <div className="col-md-5 width25">
                              <Button
                                style={{ marginTop: "11px", marginLeft: "0" }}
                                className="next_btns"
                                type="button"
                                onClick={hendelcouponcode}
                              >
                                {" "}
                                Apply{" "}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="bottom_total">
                          <div className="row">
                            <div className="col-md-6 "></div>
                            <div className="col-md-6  mt-3">
                              <ul>
                                <li>
                                  {Cartitems == 0
                                    ? null
                                    : ` SubTotal :
     
                                  ${Settings && Settings.currency.code}  ${
                                        // CartitemsAmount && CartitemsAmount.sub_total - CartitemsAmount && CartitemsAmount.tax_amount

                                        CartitemsAmount.sub_total.toFixed(2)
                                        //  CartitemsAmount.tax_amount
                                      }`}
                                </li>
                                <li>
                                  {CouponId ? (
                                    <>
                                      Discount :{" "}
                                      {Settings && Settings.currency.code}
                                    </>
                                  ) : null}{" "}
                                  {CouponId && CouponId.discount_amount}
                                </li>
                                <li>
                                  {Cartitems == 0
                                    ? null
                                    : `Tax Amount :
                               ${Settings && Settings.currency.code} ${
                                        typeof CartitemsAmount.tax_amount ===
                                        "number"
                                          ? CartitemsAmount.tax_amount.toFixed(
                                              2
                                            )
                                          : CartitemsAmount &&
                                            CartitemsAmount.tax_amount
                                      }`}
                                </li>
                                {/* <li> Delivery Charge :  $ 5.00  </li> */}
                                <li></li>

                                <li>
                                  <b>
                                    {Cartitems == 0
                                      ? null
                                      : `Total Amount :
                                    ${Settings && Settings.currency.code} 
                                    ${
                                      CouponId
                                        ? typeof CouponId.total === "number"
                                          ? CouponId.total.toFixed(2)
                                          : CouponId.total
                                        : typeof CartitemsAmount.total ===
                                          "number"
                                        ? CartitemsAmount.total.toFixed(2)
                                        : CartitemsAmount &&
                                          CartitemsAmount.total
                                    }`}
                                  </b>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <section>
                        <div className="steps_wizard">
                          <ul>
                            <li>
                              {" "}
                              <span> 1 </span>{" "}
                            </li>
                            <li className="muactive">
                              {" "}
                              <span> 2 </span>{" "}
                            </li>
                          </ul>
                        </div>
                        <div className="orderMethodsHeader">
                          <img className="mysmile" src={Smiles} alt="" />
                          <p>
                            <b>
                              {" "}
                              Your order request is ready to be sent,
                              <br /> Please choose the option and proceed.
                            </b>
                          </p>
                          <div style={{ marginTop: "5%" }}>
                            {Settings?.plan?.order_on_website == "yes" ? (
                              <div
                                className="orderMethod"
                                align="center"
                                onClick={() => setOrderMethodRadio("www")}
                              >
                                <label
                                  for="WWW_Logo"
                                  style={{ display: "inline-block" }}
                                >
                                  <input
                                    id="WWW_Logo"
                                    type="radio"
                                    checked={wwwOrderMethodRadio}
                                    onChange={() => onOrderMethodChange}
                                    value="www"
                                    name="order_method"
                                    className="orderMethodsRadioButton"
                                  />{" "}
                                  Order On Website
                                </label>
                                <img
                                  src={WWW_Logo}
                                  className=""
                                  style={{ marginLeft: "43px" }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {Settings?.plan?.whatsapp_order == "yes" ? (
                              <div
                                className="orderMethod"
                                align="center"
                                onClick={() => setOrderMethodRadio("whatsapp")}
                              >
                                <label
                                  for="Whatsapp_Logo"
                                  style={{ display: "inline-block" }}
                                >
                                  <input
                                    id="Whatsapp_Logo"
                                    type="radio"
                                    checked={whatsappOrderMethodRadio}
                                    onChange={() => onOrderMethodChange}
                                    value="whatsapp"
                                    name="order_method"
                                    className="orderMethodsRadioButton"
                                  />{" "}
                                  Order On Whatsapp
                                </label>
                                <img
                                  src={Whatsapp_Logo}
                                  className=""
                                  style={{ marginLeft: "28px" }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {/* <div className="orderMethod" align="center">
                              <label for="Telegram_Logo" style={{display: "inline-block"}}>
                                <input id="Telegram_Logo" type="radio" onChange={onOrderMethodChange} value='telegram' name="order_method" className="orderMethodsRadioButton"/> Order On Telegram 
                              </label>
                              <img src={Telegram_Logo} className="" style={{ marginLeft: "34px" }}/>
                            </div> */}
                          </div>
                          <button
                            className="placeOrderBtn"
                            onClick={handledcheckoutpage}
                            type="button"
                            number={`${WhatsAppNumber}`}
                          >
                            {" "}
                            PLACE ORDER NOW{" "}
                          </button>
                        </div>
                        <div className="wwwMethodContent">
                          <img className="mysmile" src={Smiles} alt="" />
                          <p>
                            <b>
                              {" "}
                              Order Request Placed Successfully,
                              <br /> Order ID :{" "}
                              <b style={{ fontWeight: "800" }}>
                                #{OrderidData1.order_no}
                              </b>
                            </b>
                            <br />
                            <span className="wwwMethodContentNote">
                              You can track your order using your phone number
                            </span>
                          </p>
                          <div className="www_download_qrs">
                            <div className="www_qrpics">
                              <br />
                              <QRCode
                                id="qr-gen"
                                value={`${
                                  window.location.origin
                                }/orderdetails/${
                                  OrderidData1 && OrderidData1.order_no
                                }/${
                                  OrderidData1 &&
                                  OrderidData1.customer.contact_no1
                                }`}
                                size={250}
                                level={"H"}
                                includeMargin={true}
                              />
                            </div>
                            <div
                              onClick={downloadQRCode}
                              className="downloaded_div"
                            >
                              <img src={Download} alt="" />
                              <span className="downloadQrCodeText">
                                Download <br />
                                QR Code{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <button
              className="modal_cancel"
              style={{ fontSize: "14px", marginLeft: "25px" }}
              type="button"
              onClick={checkOutClose}
            >
              <span className="uparrowGray">
                {" "}
                <img src={Uparrow} /> Close{" "}
              </span>
              <span className="uparrowRed" style={{ display: "none" }}>
                {" "}
                <img src={UparrowR} /> Close{" "}
              </span>
            </button>
          </Modal.Body>
        </Modal>

        <Modal
          show={checkoutshow1}
          onHide={checkOutClose1}
          className="track_sec"
        >
          <Modal.Body>
            <div className="myprod_sec">
              <div className="my_prod_inside">
                <div className="myprod_header">
                  <h4> Track Order </h4>
                </div>
                <Form onSubmit={handleSubmit2(hendelOrderByOrderNumber)}>
                  <div className="myprod_body">
                    <div className="form-group">
                      <label> Phone Number* </label>

                      <Controller
                        control={control2}
                        id="contact_no1"
                        name="contact_no1"
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={50}
                            placeholder="Phone Number "
                            className="form-control"
                            invalid={errors.contact_no1 && true}
                            {...field}
                          />
                        )}
                      />
                      {errors && errors.contact_no1 && (
                        <FormFeedback>Please enter a contact_no1</FormFeedback>
                      )}
                    </div>
                    <div className="form-group">
                      <label> Order ID* </label>

                      <Controller
                        control={control2}
                        id="order_no"
                        name="order_no"
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={50}
                            placeholder="Order Number "
                            className="form-control"
                            invalid={errors.order_no && true}
                            {...field}
                          />
                        )}
                      />
                      {errors && errors.order_no && (
                        <FormFeedback>Please enter a order_no</FormFeedback>
                      )}
                    </div>
                    <center
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <button className="backBtns" type="button">
                        {" "}
                        Back{" "}
                      </button>
                      <button type="submit" className="checkBtns">
                        {" "}
                        Check{" "}
                      </button>
                    </center>
                  </div>
                </Form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={checkoutshow2}
          onHide={checkOutClose2}
          className="track_sec"
        >
          <Modal.Body>
            <div className="myprod_sec">
              <div className="container">
                <div className="trackList">
                  <h3>
                    {" "}
                    My Order{" "}
                    <span
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "15px",
                      }}
                    >
                      {" "}
                      <i
                        onClick={checkOutClose2}
                        className="fa-solid fa-xmark"
                      ></i>{" "}
                    </span>{" "}
                  </h3>
                  <div className="row">
                    <div className="col-lg-3 mb-2">
                      <div className="trackList_card">
                        <ul>
                          <li>
                            {" "}
                            Order ID #{" "}
                            <b>
                              {checkordermobi1 && checkordermobi1.order_no}{" "}
                            </b>{" "}
                          </li>
                          <li>
                            {" "}
                            Phone :{" "}
                            {checkordermobi1 &&
                              checkordermobi1.customer.contact_no1}{" "}
                          </li>

                          <li>
                            {" "}
                            Total Price :{" "}
                            {checkordermobi1 &&
                              checkordermobi1.total_amount} ${" "}
                          </li>
                          <li> Order Date : 22 Apr 2022 </li>
                          <li> Order Type : Pickup </li>
                          <li>
                            {" "}
                            Status : <span> Pending </span>{" "}
                          </li>
                          <li>
                            {" "}
                            Details : Items{" "}
                            <i
                              onClick={() => hendleorderdetails()}
                              className="fa-solid fa-eye"
                            ></i>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={checkoutshow3}
          onHide={checkOutClose3}
          className="track_sec"
        >
          <Modal.Body>
            <div className="myprod_sec">
              <div
                className="my_prod_inside"
                style={{
                  maxWidth: "600px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div className="myprod_header" style={{ background: "#fff" }}>
                  <h5>
                    {" "}
                    Order ID #{" "}
                    <b>{checkordermobi1 && checkordermobi1.order_no} </b>
                    <span className="hideModule">
                      {" "}
                      <i
                        onClick={checkOutClose3}
                        className="fa-solid fa-xmark"
                      ></i>{" "}
                    </span>{" "}
                  </h5>
                </div>
                <div className="myprod_body">
                  <div className="table_outer">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          {/* <td> SN </td> */}
                          <td> Item Name </td>
                          <td> Image </td>

                          <td> Qty </td>
                          <td> Item Price </td>
                        </tr>
                      </thead>
                      <tbody>
                        {checkordermobi &&
                          checkordermobi.map((data) => {
                            if (localStorage.getItem("Arab")) {
                              return (
                                <>
                                  <tr>
                                    {/* <th>1</th> */}

                                    <td>
                                      <img
                                        src={
                                          data &&
                                          data.product.product_images[0]
                                            .vf_product_image_path
                                        }
                                      />
                                    </td>
                                    <td>
                                      {data && data.product.product_name_ar}
                                    </td>
                                    <td>{data && data.quantity}</td>
                                    <td>{data && data.amount} $</td>
                                  </tr>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <img
                                        src={
                                          data &&
                                          data.product.product_images[0]
                                            .vf_product_image_path
                                        }
                                      />{" "}
                                    </td>
                                    <td>
                                      {data && data.product.product_name_en}
                                    </td>
                                    <td>{data && data.quantity}</td>
                                    <td>{data && data.amount} $</td>
                                  </tr>
                                </>
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      <ul className="prod_total">
                        {/* <li>
                        {" "}
                        Qty <span> {OrderidData}</span>{" "}
                      </li> */}
                        <li>
                          {" "}
                          Sub Total{" "}
                          <span>
                            {" "}
                            {checkordermobi1 &&
                              checkordermobi1.total_amount +
                                checkordermobi1.discount}{" "}
                            {Settings && Settings.currency.code}{" "}
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          Tax Free <span> 14.08 $ </span>{" "}
                        </li>
                        <li>
                          {" "}
                          {checkordermobi1.discount ? (
                            <> Discount : </>
                          ) : null}{" "}
                          <span>
                            {checkordermobi1 && checkordermobi1.discount} $
                          </span>
                        </li>
                        <li>
                          {" "}
                          Total{" "}
                          <span>
                            {checkordermobi1 && checkordermobi1.total_amount} ${" "}
                          </span>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <button
                        style={{ float: "left" }}
                        onClick={checkOutClose3}
                        id="go_backmodal"
                        className="checkBtns backBtns"
                        type="button"
                      >
                        {" "}
                        Close{" "}
                      </button>
                    </div>
                    <div className="col-md-6 col-6">
                      <button
                        onClick={checkOutClose3}
                        id="lastClose"
                        className="checkBtns"
                        type="button"
                      >
                        {" "}
                        <i className="fa-solid fa-arrow-rotate-right"></i> Order
                        Again{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div>
          <Modal
            show={categoryshow}
            onHide={categoryClose}
            className="newcategory_sec newcategory_changes"
            style={{ zIndex: 9999, overflowX: "hidden", overflowY: "hidden" }}
          >
            <Modal.Body>
              <div className="myprod_sec">
                <div
                  className="my_prod_inside"
                  style={{
                    maxWidth: "600px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div
                    className="myprod_header"
                    style={{ background: "#E3DFDF" }}
                  >
                    <h5>
                      {" "}
                      Categories
                      <span>
                        {" "}
                        <img id="removeTransparent" onClick={categoryClose} />
                      </span>
                    </h5>
                  </div>
                  <div className="myprod_body">
                    <div className="blurshade"></div>
                    <div className="allCategories">
                      <div className="mb-3" style={{ textAlign: "center" }}>
                        {" "}
                        <ul> </ul>
                      </div>

                      {/* )} */}
                      <ul>
                        <li
                          style={{ fontSize: "20px", color: "red" }}
                          onClick={() => {
                            getAllFunction();
                            setcategoryshow(false);
                          }}
                        >
                          All
                        </li>{" "}
                        {category &&
                          category.map((data) => (
                            <li
                              onClick={() => handleCategoryIdClickmobile(data)}
                              className={
                                data.id == categoryIdClickMobile
                                  ? "selectedCategory"
                                  : ""
                              }
                            >
                              <Row>
                                <Col md="2"></Col>

                                <Col>{data && data.category_name} </Col>
                              </Row>
                            </li>
                          ))}
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col"> </div>
                          </div>
                        </li>
                        {/* <li> Apparel </li>
                    <li> Watches </li>
                    <li> Cloths </li>
                    <li> Bags & Accessories </li>
                    <li> Wäsche </li>
                    <li> NEU in Bekleidung</li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div
            className="line-for-catgegory"
            style={{ display: categoryshow ? "block" : "none" }}
          ></div>
        </div>
        <Modal
          show={filtershow}
          onHide={filterClose}
          className="newcategory_sec"
        >
          <Modal.Body>
            <div className="myprod_sec">
              <div
                className="my_prod_inside"
                style={{
                  maxWidth: "600px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div className="myprod_header" style={{ background: "#fff" }}>
                  <h5>
                    {" "}
                    Filters
                    <span>
                      <img id="removeFilters" onClick={filterClose} />
                    </span>
                  </h5>
                </div>

                <div className="myprod_body">
                  <div className="blurshade"></div>
                  <div className="filter_div">
                    <div className="form-group">
                      <select
                        onChange={(e) => handleFilter(e.target.value)}
                        className="form-control"
                      >
                        <option selected disabled>
                          {" "}
                          Filter by{" "}
                        </option>
                        <option value="PriceLowtoHigh">
                          Price Low to High
                        </option>
                        <option value="PriceHightoLow">
                          {" "}
                          Price High to Low
                        </option>{" "}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Home;
